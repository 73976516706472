import React from 'react';
import BaseAlert from "./base/base-alert";

/**
 * A red error alert.
 * 
 * @param heading: bold short text to display next to icon. optional.
 * @param message: text to display.
 */
 type Props = {
    heading?: string,
    message: any
};

function CriticalAlert({heading, message} : Props) {
    return (
        <BaseAlert heading={heading} message={message} type="alert-danger" icon="fa-times-circle"/>
    );
}

export default CriticalAlert;
import React from 'react';
import * as Constants from '../../../constants';
import CriticalAlert from "../../swe/critical-alert"

const linkContactUs = Constants.CONTACT_US_URL;

/**
 * A red information alert which informs that the submitted document is already in use with another identity.
 */
function DocumentAlreadyVerifiedAlert() {
    return (
        <CriticalAlert heading="Already in use" message={<>A Queensland Digital Identity using this credential already exists. If you believe this credential should not have been used please <a href={linkContactUs}>contact us</a>. If you have received this message by error please try again or go back to choose a different credential.</>}  />
    )
}

export default DocumentAlreadyVerifiedAlert;
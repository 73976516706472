import React from "react";
import * as Constants from '../constants'

const Footer = () => {

  return (
    <>
      <footer className="qg-site-footer">
        <div className="container-fluid qg-site-width">
          <div className="d-flex flex-wrap">
            <div className="col col-md-4">
              <div className="qg-footer-col-content qg-contact-us">
                <h3>Contact us</h3>
                <ul>
                  <li>
                    <p>For general enquiries, feedback, complaints and compliments:</p>
                    <p className="phone"><a href={Constants.CONTACT_US_TEL} className="d-inline" data-analytics-link-group="qg-contact-phone">{Constants.CONTACT_US_TEL_DISPLAY}</a></p>
                  </li>
                  <li className="facebook"><a href="https://www.facebook.com/TMRQld/" aria-label="Facebook /TMRQld">/TMRQld</a></li>
                </ul>
              </div>

            </div>
            <div className="col col-md-4"></div>

          </div>

          <div className="qg-legal d-flex flex-wrap align-items-end justify-content-between">
            <ul className="list-inline col-lg-6 col-md-6">
              <li><a href="https://www.qld.gov.au/help/" className="no-print">Help</a></li>
              <li><a href="https://www.qld.gov.au/legal/copyright/">Copyright</a></li>
              <li><a href="https://www.qld.gov.au/legal/disclaimer/">Disclaimer</a></li>
              <li><a href="https://www.qld.gov.au/legal/privacy/">Privacy</a></li>
              <li><a href="https://www.qld.gov.au/right-to-information/">Right to information</a></li>
              <li><a href="https://www.qld.gov.au/help/accessibility/" className="no-print">Accessibility</a></li>
              <li id="link-languages"><a href="https://www.qld.gov.au/languages/" className="no-print">Other languages</a></li>
            </ul>
            <p className="qg-copyright col-lg-4 col-md-4">&copy; The State of Queensland 1995-2022<span
              id="qg-copyright-daterange"></span></p>
          </div>

        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from 'react';
import Validator from "./validator";

export class CheckOtherDateOnOrAfterDateValidator implements Validator {
    otherDate:Date;
    setOtherDateValid: (params: boolean) => any;
    otherHint: string;
    setOtherDateHint: (params:string) => any;

    constructor(otherDate:Date,setOtherDateValid: (params: boolean) => any, otherHint:string, setOtherDateHint: (params:string) => any) {
        this.otherDate = otherDate;
        this.setOtherDateValid = setOtherDateValid;
        this.otherHint = otherHint;
        this.setOtherDateHint = setOtherDateHint;
    }
   
    /**
     * If value is >= this.otherDate and this.otherDate is set, otherDateValid is false and display its hint with this.otherHint.
     * 
     * @param value 
     * @param _setHint ignored
     * @param _hint ignored
     * @returns 
     */
    validate(value: any, _setHint: (params: string) => any, _hint: string) {
        if (value > this.otherDate && this.otherDate) {
            this.setOtherDateValid(false);
            this.setOtherDateHint(this.otherHint);
        } else if (value <= this.otherDate) {
            this.setOtherDateValid(true);
        }
        return true;
    }
}
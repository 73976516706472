import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalState from "../../hooks/useGlobalState";
import * as Constants from "../../constants"
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import MFAHandler from "../../utils/MFAHandler";
import { useAuth0 } from "@auth0/auth0-react";

import SuccessAlert from "../../components/swe/success-alert";
import PrimaryButton from "../../components/swe/primary-button";
import SecondaryButton from "../../components/swe/secondary-button";
import InfoAlert from "../../components/swe/info-alert";
import WarningAlert from "../../components/swe/warning-alert";
import Product from "../../components/qdi/product";
import { AssociateQDIUserAccountBuilder, DocumentVerificationError, verifyMarriageCertificate, XBody } from "../../services/x-api";
import DocumentInvalidAlert from "../../components/qdi/alerts/document-invalid-alert";
import DocumentVerificationFailedAlert from "../../components/qdi/alerts/document-verification-failed-alert";
import { MandatoryValidator } from "../../utils/validation/validator";
import SelectForm from "../../components/qdi/form/select-form";
import MarriageCertificateForm from "../../components/qdi/form/marriage-certificates/marriage-certificate";
import ACTMarriageCertificateForm from "../../components/qdi/form/marriage-certificates/act-marriage-certificate";
import QLDMarriageCertificateForm from "../../components/qdi/form/marriage-certificates/qld-marriage-certificate";
import NSWMarriageCertificateForm from "../../components/qdi/form/marriage-certificates/nsw-marriage-certificate";
import VICMarriageCertificateForm from "../../components/qdi/form/marriage-certificates/vic-marriage-certificate";
import {extractIpn, updateSessionTokenIpn} from "../../utils/helpers";

export const MarriageCertificate = () => {
    const navigate = useNavigate();
    const { globalState ,saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    window.sessionStorage.setItem("QDI-IPE", "IP1P:CL1");

    useEffect(() => {
        (async () => {
    
          try {
            if (!isAuthenticated) {
                // Throw exception if user not authenticated
                throw new Error("User is not authenticated!");
            }

            //await getAccessTokenSilently({ ignoreCache: true });
            const claims = await getIdTokenClaims();
    
            if (Constants.DEBUG) {
              console.log('%c[MarriageCertificate] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o\nclaims: %o', 'color: #9c6;',
                isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState, claims);
            }

            //As a temp workaround for ipn_status not getting updated in token, we are passing ip1p_nc staus via
            //globalState from travel-document-form based o the API response.
            // const gsIpn = globalState?.sessionToken?.ipn; 
            // const claimsIpn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
            // const sessionToken = localStorage.getItem('session_token');
            // saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            // globalState.sessionToken.ipn = (gsIpn === "ip1p_nc") ? gsIpn : claimsIpn;
            // if (Constants.DEBUG) { console.log('user claims ipn ->', globalState.sessionToken.ipn); }
    
            // IDP-3093 This is to prevent loosing the users nc status in their session tokens
            // between loggings causing them to be place in the wrong uplift stage
            //const gsIpn = globalState?.sessionToken?.ipn; 
            const claimsIpn = extractIpn(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
            let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token')!);
            if(sessionTokenJSON.ipn !== claimsIpn && (sessionTokenJSON.ipn !== "ip1p_nc")){            
                sessionTokenJSON.ipn = claimsIpn;
                localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
            }
            saveGlobalState({ sessionToken: sessionTokenJSON });
            saveGlobalState({ idState: JSON.parse(localStorage.getItem('state')!) });

            if (Constants.DEBUG) {
              console.log('%c[MarriageCertificate] sessionTokenJSON: %o', 'color: #9c6;', sessionTokenJSON);
            }

            // Check if user has completed this step
            if (!(globalState?.sessionToken?.ipn === "ip1p_nc" || 
                (globalState?.sessionToken?.ipn === "ip1p" && globalState?.sessionToken?.ipn_status === "nc")) || 
                (user && user.email_verified === false)) {
              if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step completed. Redirecting to uplift"); }
              navigate("/ip-uplift", { replace: true });
            }
          }
          catch (error) {
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
            // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
            // window.location.href = rp;
            navigate("/unauthorised", { replace: true });
          }
    
        })();
      }, []);

    // validators
    let mandatoryValidator = new MandatoryValidator();

    const [registrationState, setRegistrationState] = useState('');
    const [registrationStateValid, setRegistrationStateValid] = useState(true);

    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [counter, setCounter] = useState(0);
    const alertRef = useRef(null);

    //These 3 states requires CertNumber and RegNumber to be in type of number
    const statesRequireIdAsNumber = ['NSW', 'VIC', 'TAS'];

    useEffect(() => {
        if(verificationFailed || registrationStateValid || documentInvalid){
            alertRef?.current.focus();
        }

        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }
    },[verificationFailed, registrationStateValid, documentInvalid]);

    function getCertificateNumber(formFields: any) {
        if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
            return +formFields.get('certificateNumber');
        } else {
            return formFields.get('certificateNumber');
        }
    }

    function getRegistrationValue(formFields: any) {
        if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
            if (formFields.get('registrationType') === "Registration Date") {
                return formFields.get('registrationValue');
            } else {
                return +formFields.get('registrationValue');
            }
        } else {
            return formFields.get('registrationValue');
        }
    }

    const verifyMarriageCertificateForm = async () => {
        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }        
        setAlertVisibility(false, false);

        try {
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims();

            let builder = new AssociateQDIUserAccountBuilder()
                .withUserId(idToken!.sub)
                .withVerifiedDocTypeCode("MC")
                .withIssuingAuthority(formFields.get('state'))
                .withFamilyName1(formFields.get('familyName1'))
                .withFamilyName2(formFields.get('familyName2'))
                .withDateOfEvent(formFields.get('dateOfEvent'));

            if (formFields.has('givenNames1')) { builder.withGivenName1(formFields.get('givenNames1')); }
            if (formFields.has('givenNames2')) { builder.withGivenName2(formFields.get('givenNames2')); }

            if (formFields.has('certificateNumber') && formFields.get('certificateNumber').length !== 0) {
                builder.withVerifiedDocIdentifier("Certificate Number", getCertificateNumber(formFields));
            }

            if (formFields.has('registrationValue') && formFields.get('registrationValue').length !== 0) {
                builder.withVerifiedDocIdentifier(formFields.get('registrationType'), getRegistrationValue(formFields));
            }

            let certificate = new XBody(builder.build());

            await verifyMarriageCertificate(certificate, token);
            setCounter(0);

            // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
            // ipn_status not getting updated in token issue.
            const updatedSessionToken = updateSessionTokenIpn('ip2');
            saveGlobalState({ sessionToken: updatedSessionToken });

            navigate("/ip-uplift/success", { replace: true });
        }
        catch (error) {
            setCounter(counter + 1);
            if (error instanceof DocumentVerificationError) {
                if ("D" === error.failureCode) { // && "Invalid document used in EOI verification" == error.failureMessage) {
                    setAlertVisibility(false, true);
                }
                else if ("Document Temporarily Locked" === error.failureMessage) {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                }
                else if ("N" === error.failureCode && "Name not match" === error.failureMessage) {                    
                    globalState.nameDidnotMatch = true;
                    navigate("/ip-uplift/names-dont-match", { replace: true });
                }
                else if ("N" === error.failureCode) {
                    setAlertVisibility(true, false);
                }
            }
            else {
                throw error;
            }
        }
    }

    function setAlertVisibility(verifyFailed: boolean, docInvalid: boolean){    
      setVerificationFailed(verifyFailed);
      setDocumentInvalid(docInvalid);
    }

    function goBack() {    
      navigate("/ip-uplift/name-change-options", { replace: true });
    }

    const [isFormValid, setIsFormValid] = useState(false);
    // useEffect(() => {
    // }, [isFormValid]);

    const options = new Map<string, any>();
    options.set("ACT", <ACTMarriageCertificateForm setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("NSW", <NSWMarriageCertificateForm setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("NT", <MarriageCertificateForm state="NT" certificateNumberMaxLength={9} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("QLD", <QLDMarriageCertificateForm setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("SA", <MarriageCertificateForm state="SA" certificateNumberMaxLength={9} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />); // registrationNumberAllowAlphanumeric={true}
    options.set("TAS", <MarriageCertificateForm state="TAS" certificateNumberMaxLength={11} certificateNumberLengthFixed={true} validateLeadingZeros={true} registrationNumberMaxLength={6} nameFieldsCharsAllowedPattern={/[^a-zA-Z0-9 '\-]/} nameFieldsCharsAllowedTrailingHint=" ' -" setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("VIC", <VICMarriageCertificateForm setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
    options.set("WA", <MarriageCertificateForm state="WA" certificateNumberMaxLength={11} certificateNumberLengthFixed={true} registrationNumberMaxLength={7} registrationNumberLengthFixed={true} setFormFields={setFormFields} setIsFormValid={setIsFormValid} givenNamesMandatory={false}/>);

    return (
        <>
            <h1>Verify your name change</h1>
            <div tabIndex={-1} ref={alertRef}>
                {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel="Australian Marriage Certificate" /> : null}
                {documentInvalid ? <DocumentInvalidAlert /> : null}
            </div>
            
            <p>Enter your information, as it appears on your Australian Marriage Certificate.</p>

            <section className="row cards-identity qg-cards cards_equal-height">
                <Product heading="Australian Marriage Certificate" media={require('../../assets/images/Australian Marriage Certificate.png')} altText="QLD Drivers Licence" action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />
            </section>

            <SelectForm id="registration-state" label="Australian Marriage Certificate registration state"
                inputValue={registrationState} isInputValid={registrationStateValid}
                setInputValue={setRegistrationState} setInputValid={setRegistrationStateValid}
                options={options}
                noSelectionText="Please select a state"
                validators={[mandatoryValidator]}
                infoMessage={registrationState !== '' &&
                    <>
                        <p>Enter your information as it appears on your Australian Marriage Certificate.</p>
                        <InfoAlert message={<>If you experience any trouble locating the information on your document, visit the <a target="_blank" className="tmr-link" href="https://www.mygovid.gov.au/verify-a-change-in-name-marriage-certificate">myGovID website</a> for assistance.</>} />
                    </>
                }
            />

            {registrationState !== '' &&
                <WarningAlert heading="Verifying your identity" message={<><p>As part of verifying your identity, we need to check the details you have provided with the Issuer/Official record holder.</p><p>By selecting Continue, you give us your consent to check the details with the Issuer/Official record holder for the purpose of confirming your identity.</p></>} />
            }

            <div className="my-3">
                <ol className="questions">
                    <li>
                        <ul className='actions'>
                            <PrimaryButton id="submitButton" heading="Continue" action={verifyMarriageCertificateForm} disabled={!isFormValid || registrationState === ''} />
                            <SecondaryButton id="backButton" heading="Back" action={goBack} />
                        </ul>
                    </li>
                </ol>
            </div>
        </>
    )
};

export default MarriageCertificate;
import React from "react";
import MarriageCertificateForm from "./marriage-certificate";
import MarriageCertificateType from "./marriage-certificate-type";

export const VICMarriageCertificateForm = (props : Omit<MarriageCertificateType,'state'|'registrationIsDate'>) => {
    return (
        <MarriageCertificateForm 
            state="VIC" 
            givenNamesMandatory={false}  
            familyNameMaxLength={80} 
            givenNameMaxLength={80}
            certificateNumberMaxLength={12}
            registrationIsDate={false}
            {...props}
        />
    )
}

export default VICMarriageCertificateForm; 
import React, { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";

import CriticalAlert from '../components/swe/critical-alert';
import { DEBUG } from "../constants";

export const EmailAlreadyUsed = () => {
    const { logout } = useAuth0();
    const alertRef = useRef(null);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);
  
    useEffect(() => {
      (async () => {  
        if (DEBUG) { console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.'); }
  
        logout({
          localOnly: true,
        });
  
        localStorage.clear();
        sessionStorage.clear();  
      })();
    }, [])

    return (
        <>
            <div>
                <h1>Queensland Digital Identity</h1>

                <div tabIndex={-1} ref={alertRef}>
                    <CriticalAlert 
                        heading="An unexpected error has occurred." 
                        message={<p>Email address already in use. <br/> Please <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>} />
                </div>
            </div>
        </>
    )
};

export default EmailAlreadyUsed;
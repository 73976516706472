import React from 'react';
import BaseAlert from "./base/base-alert";

/**
 * An orange warning alert.
 * 
 * @param heading: bold short text to display next to icon. optional.
 * @param message: text to display.
 */
 type Props = {
    heading: string,
    message: any,
    testId?: string,
};

function WarningAlert({heading, message, testId="alert-warning"} : Props) {
    let attributes:any = {};
    if (testId) { attributes.testId = testId }
    return (
        <BaseAlert heading={heading} message={message} { ...attributes } type="alert-warning" icon="fa-exclamation-triangle" />
    );
}

export default WarningAlert;
import React from "react";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from '../constants'
import SessionManager from "./SessionManager";

import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from 'react-idle-timer'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "./Breadcrumbs";

const Header = () => {
  const linkContactUs = Constants.CONTACT_US_URL;
  const { globalState, saveGlobalState } = useGlobalState();
  //const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { user, isAuthenticated, logout, buildLogoutUrl } = useAuth0();
  const { getRemainingTime } = useIdleTimer();

  let logoutWithRedirect = () => logout({ returnTo: [window.location.origin, 'logout'].join('/') });

  if (Constants.DEBUG) {
    // const logoutUrl = buildLogoutUrl();
    // window.sessionStorage.setItem("_debug_QDI_logoutUrl", logoutUrl);
    // window.sessionStorage.setItem("_debug_QDI_globalState", JSON.stringify(globalState));
    // (async () => {
    //   let idToken, authUser;
    //   // idToken = await getIdTokenClaims();
    //   // authUser = await user;
    //   // if (idToken) { window.sessionStorage.setItem("_debug_QDI_idToken", JSON.stringify(idToken)); }
    //   // if (authUser) { window.sessionStorage.setItem("_debug_QDI_user", JSON.stringify(authUser)); }

    //   /* BELOW DISABLED: issue preventing site load if unauthenticated auth0 user */
    //   // const tokenString = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
    //   // if (tokenString) { window.sessionStorage.setItem("_debug_QDI_tokenString", JSON.stringify(tokenString)); }
    // })();

    //logoutWithRedirect = () => logout({ returnTo: [window.location.origin, 'logout'].join('/') });
    console.info('DEBUG ✔', getRemainingTime() / 60000);
  }

  return (
    <>
      <SessionManager />

      <header id="qg-site-header" className="qg-site-header sticky">
        <div className="container-fluid qg-site-width qg-site-header-top">
          <div className="row align-items-center justify-content-start">
            <button id="qg-show-menu" className="qg-show-menu" aria-label="Search" data-toggle="collapse" data-target=".qg-navigation" aria-expanded="false" aria-controls="qg-site-nav qg-portal-links">
              <div className="qg-global-icons ">
                <div className="qg-icon--menu-default">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="72" y="95" width="368" height="60" rx="15"></rect>
                      <rect x="72" y="226" width="368" height="59" rx="15"></rect>
                      <rect x="72" y="356" width="368" height="60" rx="15"></rect>
                    </g>
                  </svg>
                </div>
                <div className="qg-icon--menu-close">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect transform="translate(256.500000, 256.000000) scale(1, -1) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                      <rect transform="translate(256.500000, 256.000000) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                    </g>
                  </svg>
                </div>
              </div>
              <span>Menu</span>
            </button>
            <div className="qg-coat-of-arms">
              <a href="/" tabIndex="0">
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-white.svg" alt="Queensland Government" className="hidden-xs" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked-white.svg" alt="Queensland Government" className="hidden-sm hidden-md hidden-lg" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked.svg" alt="Queensland Government" className="hidden-xs hidden-sm hidden-md hidden-lg qg-print-coat-of-arms" />
              </a>
            </div>
            <div className="alignment-placeholder"></div>
          </div>
        </div>
        <div id="qg-portal-links" className="qg-portal-links qg-navigation collapse">
          <div className="container-fluid qg-site-width">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse" id="qgPortalLinksDropdown">
              </div >
            </nav >
          </div >
        </div >

        <div className="container-fluid qg-site-width qg-site-header-bottom">
          <div className="row align-items-center justify-content-between">

            <nav id="qg-site-nav" className="qg-navigation collapse" role="navigation" aria-label="Main navigation" style={{ width: '100%' }}>
              <ul className="nav" style={{ display: 'block' }}>
                <li className="nav-item">
                  <a className="nav-link" href={linkContactUs} data-analytics-link-group="qg-primary-nav-link">Queensland Digital Identity help</a>
                </li>
                {Constants.DEBUG && user && (
                  <>
                    <UncontrolledDropdown nav inNavbar style={{ position: 'relative', float: 'right' }}>
                      <DropdownToggle nav caret id="profileDropDown">
                        <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                        {user.email}
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem
                          id="qsLogoutBtn"
                          onClick={() => logoutWithRedirect()}
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} /> Log
                          out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}

              </ul>
            </nav>

          </div>
        </div >
      </header >


      {/* <Breadcrumbs /> */}
    </>
  );
};

export default Header;

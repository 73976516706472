import React from 'react';
import CriticalAlert from "../../swe/critical-alert"

/**
 * A red information alert which informs that the submitted document is invalid.
 * 
 */
function DocumentInvalidAlert() {
    return (
        <CriticalAlert heading="Credential is invalid" message="The credential is invalid. Please try again or go back to choose a different credential. If you believe this credential should be valid, please contact the issuing authority who issued this credential for support."  />
    )
}

export default DocumentInvalidAlert;
import React from "react";
import MarriageCertificateForm from "./marriage-certificate";
import MarriageCertificateType from "./marriage-certificate-type";

export const QLDMarriageCertificateForm = (props : Omit<MarriageCertificateType,'state'|'registrationIsDate'>) => {
    return (
        <MarriageCertificateForm 
            state="QLD" 
            registrationIsDate={true}
            certificateNumberLengthFixed={true}
            {...props}
        />
    )
}

export default QLDMarriageCertificateForm; 
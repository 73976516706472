import React, { useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InterstateDriveLicenceForm } from "../../../components/qdi/form/interstate-driver-licence/interstate-driver-licence-form";
import useGlobalState from "../../../hooks/useGlobalState";
import * as Constants from "../../../constants";
import { useAuth0 } from "@auth0/auth0-react";
import { extractIpn } from "../../../utils/helpers";


export const InterstateDriveLicencePage = () => {
    const { state } = useLocation();
    const ipnLevel = state && state.ipnLevel;
    const stateCode = state && state.stateCode;
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
  
    useEffect(() => {
      (async () => {
  
        try {
          if (!isAuthenticated) {
            // Throw exception if user not authenticated
            throw new Error("User is not authenticated!");
          }
          await getAccessTokenSilently({ ignoreCache: true });
          const claims = await getIdTokenClaims();
          const ipn = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
          const acr = user!["http://oauth.tmr.qld.gov.au"].acr;
  
          if (Constants.DEBUG) {
            console.log('user claims  ipn ->', ipn);
            console.log('user object acr ->', acr);
            console.log('%c[InterstateDriveLicencePage] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
              isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
          }
          
          saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
          if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;
  
          // Check if user should be here
          let mfaAvailable = globalState.userCompletedMFA;
          if (typeof mfaAvailable === 'undefined'
            || globalState.sessionToken.ipn !== ipnLevel
                || (user && user.email_verified === false)
                    || globalState.dlaUser) {

            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
            navigate("/ip-uplift", { replace: true });
          }
        }
        catch (error) {
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        //   const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        //   window.location.href = rp;
        navigate("/unauthorised", { replace: true });
        }  
      })();
    }, []);

    const getDLNoMinLength = (stateCode: string) => {
        switch (stateCode) {
            case "NSW":
            case "SA":
            case "TAS":
                return 6;
            case "WA":
                return 7;
            default:
                return 1; //VIC, ACT, NT
        }
    }
    const dlNoMinLength = getDLNoMinLength(stateCode);
    
    const getDLNoMaxLength = (stateCode: string) => {
        switch (stateCode) {
            case "SA":
                return 6;
            case "WA":
                return 7;
            case "NSW":
            case "TAS":
                return 8;
            default:
                return 10; //VIC, ACT, NT
        }
    }
    const dlNoMaxLength = getDLNoMaxLength(stateCode);
    
    const getIsDLNumberNumeric = (stateCode: string) => {
        switch (stateCode) {
            case "NSW":
            case "SA":
            case "TAS":
                return false;
            default:
                return true; // VIC, WA, ACT, NT
        }
    }
    const isDLNoNumeric = getIsDLNumberNumeric(stateCode);

    const fullNameMaxLength = (stateCode: string) => {
        switch (stateCode) {
            case "TAS":
                return 32;
            case "NT":
                return 37;
            default:
                return 40;
        }
    }
    const fnMaxLength = fullNameMaxLength(stateCode);
    
    const getIsCardNumberNumeric = (stateCode: string) => {
        switch (stateCode) {
            case "VIC":
            case "SA":
            case "WA":
            case "ACT":
            case "TAS":
                return false;
            default:
                return true; //NSW, NT
        }
    }
    const isCardNoNumeric = getIsCardNumberNumeric(stateCode);

    const getCardNoMinLength = (stateCode: string) => {
        switch (stateCode) {
            case "NT":
                return 6;
            case "VIC":
            case "WA":
                return 8;
            case "SA":
            case "TAS":
                return 9;
            case "NSW":
            case "ACT":
                return 10;
            default:
                return 1;
        }
    }
    const cardNoMinLength = getCardNoMinLength(stateCode);

    const getCardNoMaxLength = (stateCode: string) => {
        switch (stateCode) {
            case "VIC":
            case "NT":
                return 8;
            case "SA":
            case "TAS":
                return 9;
            default:
                return 10; //NSW, WA, ACT
        }
    }
    const cardNoMaxLength = getCardNoMaxLength(stateCode);

    return (
        <>
            {isAuthenticated && 
                <>
                    <h1>Verify your {ipnLevel === "ip1" ? "first" : "second"} credential</h1>
                    
                    <InterstateDriveLicenceForm 
                        stateCode={stateCode}
                        dlNumberMinLength={dlNoMinLength}
                        dlNumberMaxLength={dlNoMaxLength}
                        isDLNumberNumeric={isDLNoNumeric}
                        firstNameMaxLength={20}
                        middleNameMaxLength={20}
                        lastNameMaxLength={fnMaxLength}
                        cardNumberMinLength={cardNoMinLength}
                        cardNumberMaxLength={cardNoMaxLength}
                        isCardNumberNumeric={isCardNoNumeric}
                        middleNameInstructionText={stateCode === "VIC" ? "If you enter a middle name, you must enter the full name, not just your initial. If you have more than one middle name, you must enter only your first middle name - do not enter any others." : ""}
                        ipnLevel={ipnLevel}
                    />
                </>
            }
        </>
    );
}

export default InterstateDriveLicencePage;
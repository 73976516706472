import React from "react";

import { generateMFAToken } from "../services/authn-api";
import * as Constants from "../constants";

export default class MFAHandler {

    static async execute(token: string, idState: any, sub: string, upliftSessionId: string) {

        if (Constants.DEBUG) { console.log('%ctoken:%o\nidState:%o\nsub:%o\nip_uplift_session.session_id:%o', 'color: #9f3;', token, idState, sub, upliftSessionId); }

        // IDP-4314 Restore org value we saved earlier on intial entry to QDI
        // as otherwise MFA will throw error, Oops...invalid state
        // let clientId = process.env.react_app_auth0_clientId;
        // const backupKey = `a0.spajs.txs.${clientId}`;
        // const backupOfFirstAuth = window.sessionStorage.getItem(`${backupKey}.backup`) as string;

        // IDP-4468 Prevents 'Oops' screen from auth0 after MFA when user logs into QDI
        // to verify their email after having it changed via the support portal
        // if(backupOfFirstAuth) window.sessionStorage.setItem(`${backupKey}`, backupOfFirstAuth);

        const response = await generateMFAToken(token, idState, sub, upliftSessionId);
        console.log('return session token %o', response);

        // This will add https when running locally because including it in the
        // local dev env var causes auth0 to add it twice for in places causing 404s
        // Doesnt seem a problem in cloud deployments
        let auth0_uri = Constants.AUTH0_URI;
        if (!auth0_uri?.includes('https')) {
            auth0_uri = 'https://' + auth0_uri;
        }

        window.location.href = `${auth0_uri}/continue?state=${idState}&return_session_token=${response.body.Results[0]?.ReturnSessionToken}`;
    }
}

import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants"

import CriticalAlert from '../components/swe/critical-alert';
import PrimaryButton from '../components/swe/primary-button';

export const RetryLimitReachedPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const alertRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }
            }
            catch (error) {
            //     const rp = new RelyingPartyBuilder()
            //     .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
            //     .build();

            // window.location.href = rp;
            navigate("/unauthorised", { replace: true });
    }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    function cancelQDI() {
        // let params = (new URL(window.location.href)).searchParams;
        // window.location.href = new RelyingPartyBuilder()
        //                             .withRelyingParty(Constants.TMP_DLA_URI!)
        //                             .withReturnCode((params.get("returnCode"))?Number(params.get("returnCode")):8)
        //                             .withAcValue("IP1:CL2")
        //                             .build();
        navigate("/signup-paused", { replace: true });
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <h1>Queensland Digital Identity</h1>
                    <div tabIndex={-1} ref={alertRef}>
                        <CriticalAlert heading="Retry limit reached" message={<>After multiple attempts we were unable to connect to the issuing agency for this document. Please close and try again later.</>} />
                    </div>
                    
                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id='close' heading='Close' action={cancelQDI}/>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    )
};

export default RetryLimitReachedPage;
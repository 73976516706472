import { extractIpn } from "../utils/helpers";
import { Call, CallAPIGateway, CallIntegrationAPIs, APIJSONResponse, VERBS } from "./api-bridge";
import { DLAUser } from "../interfaces/DLAUser";
import { DEBUG } from "../constants";
/**
 * QDI X-API wrapper object.
 * 
 * @param 1 of
 *  AssociateQDIUserAccount
 */
export class XBody {
    AssociateQDIUserAccount: AssociateQDIUserAccount

    constructor(account: AssociateQDIUserAccount) {
        this.AssociateQDIUserAccount = account;
    }
}

/**
 * User representation object.
 * 
 * @param user_id
 * @param birthdate yyyy-MM-dd format
 * @param date_of_event yyyy-MM-dd format
 * @param family_name
 * @param given_name
 * @param middle_name
 * @param family_name_1
 * @param given_name_1
 * @param family_name_2
 * @param given_name_2
 * @param issuing_authority
 * @param verified_doc List of documents verified to the user.
 * @param type_code this will be used in new lambda based APIs instead of verified_doc_type_code
 * @param licence_number
 * @param card_number
 */
export class AssociateQDIUserAccount {
    user_id?: string;
    birthdate: string;
    date_of_event?: string;
    family_name: string;
    given_name?: string;
    middle_name?: string;
    family_name_1?: string;
    given_name_1?: string;
    family_name_2?: string;
    given_name_2?: string;
    issuing_authority: string;
    type_code?: string;
    licence_number?: string;
    card_number?: string | number;
    verified_doc?: {
        type_code: string;
        identifiers: Array<{
            value: string | number
            type: string
        }>;
        attributes?: Array<{
            value: string
            type: string
        }>;
    };

    constructor(user_id: string, birthdate: string, family_name: string, given_name: string, middle_name: string, issuing_authority: string, verified_doc?: { type_code: string; identifiers: { value: string | number; type: string; }[]; attributes: { value: string; type: string; }[]; }, date_of_event?: string, family_name_1?: string, given_name_1?: string, family_name_2?: string, given_name_2?: string, type_code?: string, licence_number?: string, card_number?: string | number) {
        this.user_id = user_id;
        this.birthdate = birthdate;
        this.family_name = family_name;
        this.given_name = given_name;
        this.middle_name = middle_name;
        this.issuing_authority = issuing_authority;
        if (verified_doc!.identifiers!.length > 0) { this.verified_doc = verified_doc; }
        if (date_of_event) { this.date_of_event = date_of_event; }
        if (family_name_1) { this.family_name_1 = family_name_1; }
        if (given_name_1) { this.given_name_1 = given_name_1; }
        if (family_name_2) { this.family_name_2 = family_name_2; }
        if (given_name_2) { this.given_name_2 = given_name_2; }
        if (type_code) { this.type_code = type_code; }
        if (licence_number) { this.licence_number = licence_number; }
        if (card_number) { this.card_number = card_number; }
    }

    toObject(): AssociateQDIUserAccount {
        return { ...this };
    }
}

export class AssociateQDIUserAccountBuilder {
    user_id: string | undefined;
    birthdate: string | undefined;
    date_of_event: string | undefined;
    family_name: string | undefined;
    given_name: string | undefined;
    middle_name: string | undefined;
    family_name_1: string | undefined;
    given_name_1: string | undefined;
    family_name_2: string | undefined;
    given_name_2: string | undefined;
    issuing_authority: string | undefined;
    verified_doc_type_code: string | undefined;
    type_code: string | undefined;
    licence_number: string | undefined;
    card_number: string | number | undefined;
    identifiers: Array<{
        value: string | number
        type: string
    }>;
    attributes: Array<{
        value: string
        type: string
    }>;

    constructor() {
        this.identifiers = []; // always init array to empty
        this.attributes = [];
        return this;
    }

    withUserId(user_id: string) {
        this.user_id = user_id;
        return this;
    }

    withBirthdate(birthdate: string) {
        this.birthdate = birthdate;
        return this;
    }

    withDateOfEvent(date_of_event: string) {
        this.date_of_event = date_of_event;
        return this;
    }

    withFamilyName(family_name: string) {
        this.family_name = family_name;
        return this;
    }

    withGivenName(given_name: string) {
        this.given_name = given_name;
        return this;
    }

    withMiddleName(middle_name: string) {
        this.middle_name = middle_name;
        return this;
    }

    withFamilyName1(family_name: string) {
        this.family_name_1 = family_name;
        return this;
    }

    withGivenName1(given_name: string) {
        this.given_name_1 = given_name;
        return this;
    }

    withFamilyName2(family_name: string) {
        this.family_name_2 = family_name;
        return this;
    }

    withGivenName2(given_name: string) {
        this.given_name_2 = given_name;
        return this;
    }

    withIssuingAuthority(issuing_authority: string) {
        this.issuing_authority = issuing_authority;
        return this;
    }

    withVerifiedDocTypeCode(verified_doc_type_code: string) {
        this.verified_doc_type_code = verified_doc_type_code;
        return this;
    }

    withDocTypeCode(type_code: string) {
        this.type_code = type_code;
        return this;
    }

    withLicenceNumber(licence_number: string) {
        this.licence_number = licence_number;
        return this;
    }

    withCardNumber(card_number: string | number) {
        this.card_number = card_number;
        return this;
    }

    withVerifiedDocIdentifier(type: string, value: string | number) {
        this.identifiers.push({ type: type, value: value });
        return this;
    }

    withVerifiedDocAttribute(type: string, value: string) {
        this.attributes.push({ type: type, value: value });
        return this;
    }

    build() {
        // it is the responsibility of the called API to reject the payload.
        let verified_doc = {
            type_code: this.verified_doc_type_code!,
            identifiers: this.identifiers!,
            attributes: this.attributes
        }
        let ua = new AssociateQDIUserAccount(this.user_id!, this.birthdate!, this.family_name!, this.given_name!, this.middle_name!, this.issuing_authority!, verified_doc, this.date_of_event!, this.family_name_1!, this.given_name_1!, this.family_name_2!, this.given_name_2!, this.type_code!, this.licence_number!, this.card_number!);
        return ua;
    }
}

/**
 * The document has already been used to verify a user account. Equivalent to the API call 409 status response.
 */
export class DocumentAlreadyVerifiedError extends Error {
    constructor() {
        super("Document has already been used to verify an account.");
    }
}

/**
 * The document's details are incorrect, ineligable or not found.
 */
export class DocumentVerificationError extends Error {
    failureCode: string;
    failureMessage: string;

    constructor(failureCode: string, failureMessage: string) {
        super("Document failed Verification with code " + failureCode);
        this.failureCode = failureCode;
        this.failureMessage = failureMessage;
    }
}

/**
 * Unable to contact issuing agency
 */
export class UnableToContactIssuingAgencyError extends Error {

    constructor() {
        super("Unable to contact issuing agency.");
    }
}

/**
 * API that verify's the entered details of a driver's licence.
 * 
 * @param licence an XBody object containing the driver's licence details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @throws DocumentAlreadyVerifiedError when http status code is 409
 * @throws DocumentVerificationError when http status code is 404
 * @throws Error("Unhandled response") if not above conditions or success occurring.
 */
export async function verifyDriversLicence(licence: XBody, token: string) {
    const returned = await Call('qdi/users/identity/dl', VERBS.POST, licence, token, [200, 400, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a user's PIC identification.
 * 
 * @param pic an XBody object containing the PIC identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
export async function verifyPIC(pic: XBody, token: string) {
    const returned = await Call('qdi/users/identity/pic', VERBS.POST, pic, token, [200, 400, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a user's marine licence identification.
 * 
 * @param ml an XBody object containing the Marine Licence identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
export async function verifyMarineLicence(ml: XBody, token: string) {
    const returned = await Call('qdi/users/identity/ml', VERBS.POST, ml, token, [200, 400, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a passport.
 * 
 * @param passport an XBody object containing passport identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
export async function verifyPassport(passport: XBody, token: string) {
    const returned = await Call('qdi/users/identity/pp', VERBS.POST, passport, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a birth certificate.
 * 
 * @param certificate an XBody object containing birth certificate details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyBirthCertificate(certificate: XBody, token: string) {
    const returned = await Call('qdi/users/identity/bc', 'POST', certificate, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a passport visa.
 * 
 * @param visa an XBody object containing visa identification details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyVisa(visa: XBody, token: string) {
    const returned = await Call('qdi/users/identity/vi', VERBS.POST, visa, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a citizenship certificate.
 * 
 * @param cc an XBody object containing citizenship certificate details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyCitizenshipCertificate(cc: XBody, token: string) {
    const returned = await Call('qdi/users/identity/cc', VERBS.POST, cc, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a medicare card.
 * 
 * @param md an XBody object containing medicare card details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyMedicareCard(md: XBody, token: string) {
    const returned = await Call('qdi/users/identity/md', VERBS.POST, md, token, [200, 400, 409, 500]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of an ImmiCard.
 * 
 * @param cc an XBody object containing immicard details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyImmiCard(immicard: XBody, token: string) {
    const returned = await Call('qdi/users/identity/im', VERBS.POST, immicard, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a marriage certificate.
 * 
 * @param marriageCertificate 
 * @param token 
 * @returns 
 */
export async function verifyMarriageCertificate(marriageCertificate: XBody, token: string) {
    const returned = await Call('qdi/users/identity/mc', VERBS.POST, marriageCertificate, token, [200, 400]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a name change certificate.
 * 
 * @param nameChangeCertificate 
 * @param token 
 * @returns 
 */
export async function verifyNameChangeCertificate(nameChangeCertificate: XBody, token: string) {
    const returned = await Call('qdi/users/identity/nc', VERBS.POST, nameChangeCertificate, token, [200, 400]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that deletes the latest document uploaded to Auth0 of a given user.
 * 
 * @param userId 
 * @param token 
 * @returns 
 */
export async function deleteLatestDocument(userId: string, token: string) {
    userId = encodeURI(userId);
    const response = await Call(`qdi/users/${userId}/identity/latestdocument`, VERBS.DELETE, null, token, [200, 204, 400]);

    if (response?.code === 200) {
        return response.body?.Results[0]?.DeletedDocType;
    }
    else {
        throw new Error("Unhandled response.")
    }
}

/**
 * Get the QDI Service Status from QDI-Service-Status API.
 * @returns the server response if the response is a 200 response.
 */
export async function getQDIServiceStatus() {

    const response = await CallAPIGateway(`service-status`, VERBS.GET, null, "");

    if (response?.code === 200) {
        if (response?.body[0].service_name === 'QDI') {
            return response.body[0];
        } else {
            throw new Error("Unhandled response.")
        }
    }
    else {
        throw new Error("Unhandled response.")
    }
}

/**
 * Get the Document Types to display on Verify Credentials page
 * @param user_id the auth0 user id of this user in the format of auth0|xxxxxxxxxxxxxxx
 * @param ipnLevel Ipn level of the user
 * @param dlaUser Users from DLA Eligibility app, otherwise null
 * @param accessToken Auth0 authentication token used to access APIs
 * @returns the server response if the response is a 200 response.
 */
export async function getDocList(user_id: string, ipnLevel: string, dlaUser: DLAUser, accessToken: string) {

    const removeItem = (arr: string[], itemToRemove: string): string[] => {
        return arr.filter((item) => item !== itemToRemove);
    };

    const injectISDL = (arr: string[]) => {
        let newDocList: string[] = [];
        let docBeforeISDL = arr.includes("PP") ? "PP" : "ML";
        arr.forEach((docType:string) => {
            newDocList.push(docType);
            if(docType === docBeforeISDL) { // Inject IDSL after PP or ML
                newDocList.push("ISDL");
            }
        });
        return newDocList;
    }

    let docsToDisplay = [];

    if (ipnLevel === 'ip1') { // First doc options...

        docsToDisplay.push('DL');
        docsToDisplay.push('PIC');
        docsToDisplay.push('ML');

        if (!dlaUser) {
            docsToDisplay.push('PP');
            docsToDisplay.push('ISDL');
            docsToDisplay.push('VI');
            docsToDisplay.push('IM');
        }
    }

    if (ipnLevel === 'ip1p') { // Second doc options...

        const encodedUserId = encodeURIComponent(user_id);
        const getDocsResponse = await CallIntegrationAPIs(`api/users/identity/DocOptions?user_id=${encodedUserId}`, VERBS.GET, undefined, accessToken);

        if (DEBUG) console.log('DocOptions response, %o', getDocsResponse);   
        docsToDisplay = getDocsResponse.body.docOptions;
        if (!dlaUser && docsToDisplay.includes('DL')) {
            docsToDisplay = injectISDL(docsToDisplay); // Inject ISDL at the correct possition
        }

        if (dlaUser) {
            if (DEBUG) console.log('Filtering docs for ip1p DLA user'); 

            if (docsToDisplay.includes('DL')) {
                // IF DL in the list of available docs, make this the only option
                docsToDisplay = [];
                docsToDisplay.push('DL');
                docsToDisplay.push('PIC');
                docsToDisplay.push('ML');

            } else {
                // TMR product already used, filter Medicare Card from remaining available options
                docsToDisplay = removeItem(docsToDisplay, 'MD');
            }
        }
    }

    if (ipnLevel === 'ip2' && dlaUser) { // DLA users only when third doc required

        if (dlaUser.result === 'TMR document required') {
            if (DEBUG) console.log('DLA user requires TMR document');
            docsToDisplay.push('DL');
            docsToDisplay.push('PIC');
            docsToDisplay.push('ML');
        }

        if (dlaUser.result === 'Third document required') {
            if (DEBUG) console.log('DLA user requires third document');

            // Third doc required means they've already used a TMR product. Medicare Card not an option.

            // Remove second doc as an option if known
            // UPDATE: No, currently no scenario where this is needed based on current rules.
            // if (dlaUser.secondDoc !== 'PP') docsToDisplay.push('PP');
            // if (dlaUser.secondDoc !== 'VI') docsToDisplay.push('VI');
            // if (dlaUser.secondDoc !== 'CC') docsToDisplay.push('CC');
            // if (dlaUser.secondDoc !== 'IM') docsToDisplay.push('IM');
            // if (dlaUser.secondDoc !== 'BC') docsToDisplay.push('BC');
            docsToDisplay.push('PP');
            docsToDisplay.push('VI');
            docsToDisplay.push('CC');
            docsToDisplay.push('IM');
            docsToDisplay.push('BC');
        }
    }

    if (DEBUG) console.log('Returning doc options %o', docsToDisplay);
    return { status: 200, docList: docsToDisplay };
}

/**
 * Performs an eligibility check on a user for the Queensland Digital Licence
 * @param token the auth0 authentication token
 * @param userId the auth0 user ID for this user
 * @param dlaUser JSON object holding details of users eligibility
 * @returns server HTTP response
 */
export async function eligibilityCheck(token: string, userId: string, dlaUser: DLAUser) {

    if (DEBUG) { console.log('Performing eligibility check for user %s', userId); }

    const payload = { DLAUserAccount: { user_id: userId } };
    const response = await Call('qdi/users/dla/eligibilitycheck', VERBS.POST, payload, token, [200, 404]);

    if (DEBUG) console.log('Response from API eligibility call ->', response);

    if (response.code === 200) {
        dlaUser.result = response.body.Results[0].Message;
    } else {
        dlaUser.result = response.body.Results[0].ErrorResponse.AdditionalDetails;
    }
    
    return { status: response.code };
}

/**
 * API that verify's the entered details of an interstate driver's licence.
 * 
 * @param licenceDetails an AssociateQDIUserAccount object containing the driver's licence details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @throws DocumentAlreadyVerifiedError when http status code is 409
 * @throws DocumentVerificationError when http status code is 404
 * @throws Error("Unhandled response") if not above conditions or success occurring.
 * @returns true if succesfull
 */
export async function verifyInterstateDriversLicence(stateName: string, licenceDetails: AssociateQDIUserAccount, token: string) {
    const returned = await CallIntegrationAPIs('api/users/identity/dl/' + stateName, VERBS.POST, licenceDetails, token);

    return handleIntegrationAPIResponse(returned);
}

/**
 * If not a 200 response, throws an Error subclass.
 * 
 * @param response APIJSONResponse instance from api-bridge call.
 * @returns  true if succesfull
 */
function handleDocumentVerificationResponse(response: APIJSONResponse) {
    if (response.code === 200) {
        return true;
    }
    else if (response.code === 409) {
        throw new DocumentAlreadyVerifiedError();
    }
    else if (response.code === 404 || response.code === 400) {
        throw new DocumentVerificationError(response.body.Results[0]?.ErrorResponse.Indicator,
            response.body.Results[0]?.ErrorResponse.Description);
    }
    else if (response.code === 500 && response.body) {
        if (response.body.Results[0]?.ErrorResponse.Description === "Unable to contact issuing authority") {
            throw new UnableToContactIssuingAgencyError();
        }
    }

    throw new Error("Unhandled response.");
}


/**
 * If not a 200 response, throws an Error subclass.
 * 
 * @param response APIJSONResponse instance from api-bridge call.
 * @returns  true if succesfull
 */
function handleIntegrationAPIResponse(response: APIJSONResponse) {
    if (response.code === 200) {
        return true;
    }
    else if (response.code === 409) {
        throw new DocumentAlreadyVerifiedError();
    }
    else if (response.code === 404 || response.code === 400) {
        throw new DocumentVerificationError(response.body.Indicator,
            response.body.Message);
    }
    else if (response.code === 500 && response.body) {
        if (response.body.Message === "Unable to contact issuing authority") {
            throw new UnableToContactIssuingAgencyError();
        }
    }
    throw new DocumentAlreadyVerifiedError();
    //throw new Error("Unhandled response.");
}
import SuccessAlert from "../../swe/success-alert";

/**
 * Green colour success alert which informs that the user's submitted document is valid but
 * the date of birth does not match with the previous documents.
 */

export const DoBMismatchAlert = () => {
    return (
        <SuccessAlert
            message="Both your credentials have successfully been verified, however your date of birth is different on each and does not match. You can try entering your information again or go back and select a another document that has the same date of birth as your first document."
        />
    );
}

export default DoBMismatchAlert;
import React, { useEffect, useRef, createElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../../hooks/useGlobalState";
import * as Constants from "../../constants";
import { extractIpn, determineCorrectIpnValue } from "../../utils/helpers";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import Product from "../../components/qdi/product";
import SecondaryButton from "../../components/swe/secondary-button";
import MedicareForm from "../../components/qdi/form/medicare-form";
import { useState } from "react";
import { verifyMedicareCard } from "../../services/x-api";


export const MedicareCardForm = () => {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { state } = useLocation();
    const { mdtype } = state;
    const ipnLevel = state && state.ipnLevel;
    let mdLable = '';
    let imageURL = require('../../assets/images/Australian Citizenship Certificate.png');

    const getMDCardLabel = (mdCardType: string) => {
        switch (mdCardType) {
            case 'G':
                mdLable = 'Australian resident Medicare card';
                imageURL = require('../../assets/images/MedicareCardGreen.png');
                break;
            case 'Y':
                mdLable = 'Australian Medicare Reciprocal Health Care card';  
                imageURL = require('../../assets/images/MedicareCardYellow.png');
                break;   
            case 'B':
                mdLable = 'Australian Medicare Interim card';
                imageURL = require('../../assets/images/MedicareCardBlue.png');
                break;   
            default:
                break;
        }
    }

    getMDCardLabel(mdtype); 

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const claimsIpnFromAcr = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
                const ipn = determineCorrectIpnValue(claimsIpnFromAcr!, user!['http://oauth.tmr.qld.gov.au'].ipn);
      
                if (Constants.DEBUG) {
                    console.log('users ipn ->', ipn);
                    console.log('%c[MedicareCardForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined'
                    || globalState.dlaUser
                        || globalState.sessionToken.ipn !== "ip1p"
                            || (user && user.email_verified === false)) {

                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                // window.location.href = rp;
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []);

    return (
    <>
        {isAuthenticated && 
            <div>
                <h1>Verify your second credential</h1>

                <MedicareForm
                    id={mdLable}
                    instructions={createElement('p', {}, 'Enter your information exactly as it appears on your Australian Medicare Card.')}
                    product={createElement(Product, 
                        { heading:mdLable, 
                          media:imageURL,
                          selected:true, 
                          disabled:true})}
                    backNavigateTo="/ip-uplift/verify-your-identity/medicare-card-type"
                    nextNavigateTo=''
                    typeCode="MD"
                    identifierType="Medicare Number"
                    action={verifyMedicareCard}
                    selectedMedicareCardType={mdtype}
                    ipnLevel={ipnLevel}
                />
            </div>
        }
    </>
    );
}

export default MedicareCardForm;
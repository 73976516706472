import CriticalAlert from "../components/swe/critical-alert";
import * as Constants from "../constants";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef } from "react";

/**
 * Displays a session timeout page
 * 
 * If the user has come from a recognised RP then link will be displayed back to RP
 * otherwise just session timeout message is displayed
 * 
 * @returns the page
 */
export default function SessionTimeout() {

    const { logout } = useAuth0();
    const alertRef = useRef(null);

    if (Constants.DEBUG) { console.log('Session timed out!'); }

    const handleSilentLogout = async () => {
        logout({ localOnly: true, });
        localStorage.clear();
        sessionStorage.clear();
    };

    useEffect(() => {
        alertRef?.current?.focus();
        handleSilentLogout();
    }, [])

    return (<>
        <div>
            <h1>Your session has expired due to inactivity</h1>

            <div tabIndex={-1} ref={alertRef}>
                <CriticalAlert 
                    message={
                        <>
                            <p>In order to continue you will need to return to the agency, service or application that you were attempting to use.</p>
                            <p>We apologise for any inconvenience.</p>
                        </>
                    } 
                />
            </div>
        </div>
    </>);
}
/**
 * Validate dates for dd/mm/yyyy format. 
 */

import Validator from "./validator";

export class LabelDateInputValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) {
        let year = (value) ? value.split('-')[0] : null;
        if (checkDateFormatInvalid(value) || checkDateInvalid(value)) {
            setHint(label +' is invalid');
            return false;
        }
        else if (year && (year > new Date().getFullYear() || year < 1900)) {
            setHint(label +` can only be between the year 1900-${new Date().getFullYear()}`);
            return false;
        }
        else {
            return true;
        }       
    }
}

function checkDateInvalid(userDate:string) {
    let valid = true;        
    
    let tokens = userDate.split('-');
    let days = Number(tokens[2]);
    let month = Number(tokens[1]);
    let checkIsActualDate = new Date(Number(tokens[0]),month-1,days);
    
    if (month > 12 || month ==0 || days==0 || days >31 || (month-1) !== checkIsActualDate.getMonth()) {
        valid = false;
    }
    else if (isNaN(checkIsActualDate.getTime())) {
        valid = false;
    }
        
    return !valid;
}

/**
 * Checks for dd/mm/yyyy format.
 * @param {*} userDate 
 */
function checkDateFormatInvalid(userDate:string) {
    let dmyTest = (/^(\d{1,4})-(\d{1,2})-(\d{1,2})$/.test(userDate));

    return !dmyTest;
}

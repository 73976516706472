import React, { useState, useRef, useEffect } from "react";
import Label from "./base/Label";
import InputTypeProps from "./base/input-type";
import { executeValidators } from "../../utils/validation/validator";

/**
 * MedicareNumberInput component allows users to enter medicare number in xxxx-xxxxx-x format. 
 * props.inputValue field will hold a string value with two hyphens (-) unless its empty.
 * User of this component should always use medicareCardNumber.replaceAll('-', '') method to get 10 digit medicare number.
 */

interface MedicareNumberInputTypeProps extends InputTypeProps {
    labelClassName?: string
}

export const MedicareNumberInput = (props : MedicareNumberInputTypeProps) => {
    const [hint,setHint] = useState('');
    const secondNumberRef = useRef(null);
    const lastNumberRef = useRef(null);
    let  errorStyle = {
        //backgroundColor:  "#d600000d",
        borderColor: "#d60000",
    }

    const validateFirst4Digits = (event:any) => {
        let mdNumber = props.inputValue === '' ? '--' : props.inputValue;
        let mdNumberParts = mdNumber.split('-');
        let enteredNumber = event.target.value;

        if(enteredNumber.length > 4 || enteredNumber > 9999 || enteredNumber < 0) {
            enteredNumber = mdNumberParts[0];
        } else {
            mdNumber = enteredNumber + '-' + mdNumberParts[1] + '-' + mdNumberParts[2];
        }
        
        if(enteredNumber.length >= 4) {
            secondNumberRef?.current?.focus();
        }

        executeValidators(mdNumber, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid!);
        props.setInputValue(mdNumber);
    }

    const validateNext5Digits = (event:any) => {
        let mdNumber = props.inputValue === '' ? '--' : props.inputValue;
        let mdNumberParts = mdNumber.split('-');
        let enteredNumber = event.target.value;

        if(enteredNumber.length > 5 || enteredNumber > 99999 || enteredNumber < 0) {
            enteredNumber = mdNumberParts[1];
        } else {
            mdNumber = mdNumberParts[0] + '-' + enteredNumber + '-' + mdNumberParts[2];
        }
        
        if(enteredNumber.length >= 5) {
            lastNumberRef?.current?.focus();
        }

        executeValidators(mdNumber, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid!);
        props.setInputValue(mdNumber);
    }

    const validateLastDigit = (event:any) => {
        let mdNumber = props.inputValue === '' ? '--' : props.inputValue;
        let mdNumberParts = mdNumber.split('-');
        let enteredNumber = event.target.value;

        if(enteredNumber.length > 1 || enteredNumber > 9 || enteredNumber < 0) {
            enteredNumber = mdNumberParts[2];
        } else {            
            mdNumber = mdNumberParts[0] + '-' + mdNumberParts[1] + '-' + enteredNumber;
        }

        executeValidators(mdNumber, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid!);
        props.setInputValue(mdNumber);
    }

    return (
        <>
            <Label 
                id={props.id} 
                label={props.label} 
                mandatory={props.mandatory} 
                persistentHint={props.persistentHint && props.hintPosition==='above' ? props.persistentHint : null} 
                className={props.labelClassName}
            />
            {!props.isInputValid? <em className="error-label" style={{ color: "#d60000"}}>{(props.inputHint) ? props.inputHint : hint}</em> : null }
            <div className="container">
                <div className="row">
                    <div className="col-3 qdi-date-input-wrapper">
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'First4Digits'}
                                id={props.id+'First4Digits'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[0] : ''}
                                pattern="[0-9]*"
                                max={9999}
                                min={0}
                                onBlur={validateFirst4Digits}
                                onChange={validateFirst4Digits}
                                disabled={props.disabled}
                                style={props.isInputValid ? {} : errorStyle }
                            />
                        </div>
                    </div>
                    <div className="col-1 qdi-date-seperator"></div>
                    <div className="col-4 qdi-date-input-wrapper">
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'5thTo9thDigits'}
                                id={props.id+'5thTo9thDigits'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[1] : ''}
                                pattern="[0-9]*"
                                max={99999}
                                min={0}
                                onBlur={validateNext5Digits}
                                onChange={validateNext5Digits}
                                ref={secondNumberRef}
                                disabled={props.disabled}
                                style={props.isInputValid ? {} : errorStyle }
                            />
                        </div>
                    </div>
                    <div className="col-1 qdi-date-seperator"></div>
                    <div className="col-2 qdi-date-input-wrapper">
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'LastDigit'}
                                id={props.id+'LastDigit'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[2] : ''}
                                pattern="[0-9]*"
                                max={9}
                                min={0}
                                onBlur={validateLastDigit}
                                onChange={validateLastDigit}
                                ref={lastNumberRef}
                                disabled={props.disabled}
                                style={props.isInputValid ? {} : errorStyle }
                            />
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
        </>
    );
}

export default MedicareNumberInput;
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";

import SuccessAlert from "../components/swe/success-alert";
import WarningAlert from "../components/swe/warning-alert";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import Product from "../components/qdi/product";
import useGlobalState from "../hooks/useGlobalState";
import { extractIpn, updateLocalStorageSessionTokenIpnAndStatus } from "../utils/helpers";
import { deleteLatestDocument } from "../services/x-api";

export const NameChangeOptions = () => {

    let [cancel, setCancel] = useState(false); 
    const [isFormValid, setIsFormValid] = useState(false);
    const [returnToPrevScrn, setReturnToPrevScrn] = useState(false);
    const [contactUs, setContactUs] = useState(false);
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);

    useEffect(() => {
        (async () => {
    
            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
    
                //await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
        
                if (Constants.DEBUG) {
                  console.log('%c[NameChangeOptions] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o\nclaims: %o', 'color: #9c6;',
                    isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState, claims);
                }
        
                // IDP-3093 This is to prevent loosing the users nc status in their session tokens
                // between loggings causing them to be place in the wrong uplift stage
                //const gsIpn = globalState?.sessionToken?.ipn; 
                const claimsIpn = extractIpn(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
                let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token')!);
                if(sessionTokenJSON.ipn !== claimsIpn && (sessionTokenJSON.ipn !== "ip1p_nc")){            
                    sessionTokenJSON.ipn = claimsIpn;
                    localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
                }
                saveGlobalState({ sessionToken: sessionTokenJSON });
    
                if (Constants.DEBUG) {
                  console.log('%c[NameChangeOptions] sessionTokenJSON: %o', 'color: #9c6;', sessionTokenJSON);
                }    

                // Check if user has completed this step
                if (!(globalState?.sessionToken?.ipn === "ip1p_nc" || 
                    (globalState?.sessionToken?.ipn === "ip1p" && globalState?.sessionToken?.ipn_status === "nc")) || 
                    (user && user.email_verified === false)) {
                  if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step completed. Redirecting to uplift"); }
                  navigate("/ip-uplift", { replace: true });
                }
              }
              catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                // window.location.href = rp;
                navigate("/unauthorised", { replace: true });
              }    
        })();
      }, []);

    useEffect(() => {        
        focusRef?.current.focus();
    }, []);
    
    function doCancel() {
        if (!cancel) {
            setCancel(true);
        } else {
            // window.location.href = new RelyingPartyBuilder()
            // .withRelyingParty(Constants.TMP_DLA_URI!)
            // .withAcValue("IP1P_NC:CL2")
            // .withReturnCode(7)
            // .build();
            navigate("/signup-paused", { replace: true });
        }
    }

    function setReturnToPrevScrnValue(checked: boolean) {
        setReturnToPrevScrn(checked);
        setContactUs(!checked);
    }

    function setContactUsValue(checked: boolean) {
        setContactUs(checked)
        setReturnToPrevScrn(!checked);
    }

    function continueWithSelectedOption() {
        if (contactUs) {
            window.location.href = Constants.CONTACT_US_URL;
        } else if (returnToPrevScrn) {
            deleteSecondCredential();   
        }
    }

    const deleteSecondCredential = async () => {
        try {
            localStorage.removeItem("deleted-doc-type"); // Clear localStorage attribute
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims();

            const deletedDocType = await deleteLatestDocument(idToken!.sub, token);

            if (deletedDocType) {
                localStorage.setItem("deleted-doc-type", deletedDocType);
                updateLocalStorageSessionTokenIpnAndStatus("ip1p", ""); //set localStorage sessionToken ipn status to blank   
                navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: 'ip1p' } });
            }
        } catch (error) {
            throw error;
        }
    }

    const displayNameChangeCertificateForm = () => {
        navigate("/ip-uplift/name-change-options/change-of-name-certificate-form", { replace: true });
    }

    const displayMarriageCertificateForm = () => {
        navigate("/ip-uplift/name-change-options/marriage-certificate-form", { replace: true });
    }

    return (
        <>
            <div tabIndex={-1} ref={focusRef}></div>
            <h1>Verify your name change</h1>

            <SuccessAlert message={<><p>Your credentials have been successfully verified. However, your name is different on two of your credentials, which indicates that you changed your name at some point in the past.</p></>} />
            
            <p>Please select one of the options below to continue:</p>

            <section className="row cards-identity qg-cards">
                <Product 
                    heading="Australian Change of Name Certificate"
                    action={displayNameChangeCertificateForm}
                    media={require('../assets/images/Australian Name Change Certificate.png')}
                    imgStyles={{border: '1px solid #707070'}}
                />
                <Product 
                    heading="Australian Marriage Certificate"
                    media={require('../assets/images/Australian Marriage Certificate.png')}
                    action={displayMarriageCertificateForm}
                    imgStyles={{border: '1px solid #707070'}}
                />
            </section>

            <p>If you do not have either of these documents select one of the following options:</p>

            <form className="qg-forms-v2">
                <ol className="questions">
                    <li>
                        <fieldset>
                            <ul className="choices qg-forms-v2__radio">
                                <li>
                                    <input 
                                        type="radio" 
                                        name="name-change-options" 
                                        id="return-to-previous"
                                        checked={returnToPrevScrn}
                                        onChange={(e) => setReturnToPrevScrnValue(e.target.checked)}
                                    />
                                    <label htmlFor="return-to-previous">Return to the previous screen and select another credential</label>
                                </li>
                                <li>
                                    <input 
                                        type="radio" 
                                        name="name-change-options" 
                                        id="contact-support"
                                        checked={contactUs}
                                        onChange={(e) => setContactUsValue(e.target.checked)}
                                    />
                                    <label htmlFor="contact-support">Contact us to continue setting up your Queensland Digital Identity (if you do not have an Australian Change of Name Certificate, Australian Marriage Certificate or another suitable credential)</label>
                                </li>
                            </ul>
                        </fieldset>
                    </li>
                    <li className="footer">
                        {cancel &&
                            <WarningAlert heading="Sure you want to cancel?" message={<><p>If you cancel, you will be returned to the service page, and your identity will not be verified.</p><p>You will be able to verify your identity later by logging in using your email and password, and continuing this process.</p><p>Select Cancel if you wish to cancel this process.</p></>} />
                        }
                        <ul className="actions">
                            <PrimaryButton id='continueButton' heading='Continue' action={continueWithSelectedOption} disabled={!(returnToPrevScrn || contactUs)}/>
                            <SecondaryButton id="cancelButton" heading="Cancel" action={doCancel}/>
                        </ul>
                    </li>
                </ol>
            </form>
        </>
    );
};

export default NameChangeOptions;
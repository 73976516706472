import React, {useState}  from "react";
import AttributeFactory from "../../../utils/AttributeFactory";
import Label from "./Label";
import SelectInputTypeProps from "./select-type";

/**
 * Base label and select input with options. 
 *
 * @returns 
 */
export const LabelSelect = (props: SelectInputTypeProps) => {
    const [hint,setHint] = useState('');
    let attributes = AttributeFactory.build(props, setHint);

    return (
        <>
            <Label id={props.id} label={props.label} mandatory={props.mandatory} persistentHint={props.persistentHint && props.hintPosition==='above' ? props.persistentHint:null}/>
            {!props.isInputValid? <em className="error-label">{hint}</em> : null }
            <select {...attributes}>
                <option key={`${props.id}-nosel`} value="">{ props.noSelectionText }</option>
                {
                    props.options.map((o, i) => <option key={`${props.id}-${i}`} value={""+o} disabled={props.setOptionsDisabled && props.setOptionsDisabled!.includes(o)}>{o}</option>)
                }
            </select>
            {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
        </>
    )
}

LabelSelect.defaultProps = {
    hintPosition: "above",
    noSelectionText: "Please select",
}

export default LabelSelect;

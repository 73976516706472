import CriticalAlert from "../components/swe/critical-alert";
import {DEBUG} from "../constants";
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef } from 'react';

const Unauthorized = () => {

  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {

      if (DEBUG) { console.log('Unauthorized access to page!'); }

      // Not stricly necassary as the user isnt logged in but lets be sure
      logout({ localOnly: true, });
      localStorage.clear();
      sessionStorage.clear();

    })();
  }, [])

  return (
    <>
      <div>
        <h1>Queensland Digital Identity</h1>
        <div tabIndex={-1}>

          <CriticalAlert heading="Unauthorised Access" message={
            <div>
              <p>Access to the Queensland Digital Identity is restricted to approved systems and networks.</p>
              <p> The link or application you have used to try to access the Queensland Digital Identity is not approved.</p>
            </div>
          } />
          
        </div>
      </div>
    </>
  )
};

export default Unauthorized;
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";

// state
import useGlobalState from "../hooks/useGlobalState";

// APIs
import { sendVerificationCode, verifyVerificationCode } from "../services/authn-api";
import { DocumentVerificationError, DocumentAlreadyVerifiedError } from "../services/x-api";
import { FixedLengthValidator, PatternValidator } from "../utils/validation/validator"
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import { extractIpn, extractCl } from "../utils/helpers";

// UI components
import Callout from "../components/swe/base/callout";
import SuccessAlert from "../components/swe/success-alert";
import InfoAlert from "../components/swe/info-alert";
import WarningAlert from "../components/swe/warning-alert";
import LabelInput from "../components/swe/base/label-input";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";

export const EnterEmailOneTimeCode = () => {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect } = useAuth0();
    const alertRef = useRef(null);
    const focusRef = useRef(null);

    const [cancel, setCancel] = useState(false);

    let clValue;

    window.sessionStorage.setItem("QDI-IPE", "IP1:CL1");

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();

                if (Constants.DEBUG) {
                    console.log("Users claims ->", claims);
                    console.log('%cisAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
                clValue = extractCl(claims['http://oauth.tmr.qld.gov.au'].acr);
                if (Constants.DEBUG) { console.log('user claims  ipn ->', ipn); }
                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });

                // if we get an ipn from the claims above, store the value in the session token, otherwise leave as is
                // This fixes issue where user logs in after having email reset and re-verified and claims object is empty
                // Weird.
                if (ipn && (globalState.sessionToken.ipn !== "ip1p_nc" && globalState.sessionToken.ipn !== "ip2")) globalState.sessionToken.ipn = ipn;

                // Test if authenticated user has already verified email
                if (user && user.email_verified) {
                    console.log('User already verified their email. Redirecting to uplift...');
                    navigate("/ip-uplift", { replace: true });
                } else {
                    // Authenticated user needs to verify email
                    if (performance?.getEntriesByType("navigation")[0]?.type !== "reload"
                        && performance?.getEntriesByType("navigation")[0]?.type !== "back_forward") {
                        sendEmail();
                    }
                }

            } catch (error) {
                console.log('%c%s', 'color: #c00;', error);
                // const rp = new RelyingPartyBuilder()
                //     .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                //     .build();
                // console.log("Redirecting unauthenticated user to ", rp);
                // window.location.href = rp;
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []);

    useEffect(() => { //Controls the Enter button behaviour
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter" || event.keyCode === 13) {
                event.preventDefault();
                //verifyEmail();
                document.getElementById("submitButton").click();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeResent, setVerificationCodeResent] = useState(false);
    const [verificationCodeValid, setVerifcationCodeValid] = useState(true);
    const [verificationCodeIncorrect, setVerificationCodeIncorrect] = useState(false);
    const [verificationCodeExpired, setVerificationCodeExpired] = useState(false);
    const [verificationCodeIncorrectCount, setVerificationCodeIncorrectCount] = useState(0);
    const [countDown, setCountDown] = useState(0);
    const [attempts, setAttempts] = useState(0);

    let codeLengthValidator = new FixedLengthValidator(6, true);
    let patternValidator = new PatternValidator(/[^0-9]/, "Digital Identity verification code must be 6 digits");

    useEffect(() => {
        countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
    }, [countDown]);

    useEffect(() => {
        if (verificationCodeIncorrect || verificationCodeExpired || verificationCodeResent || globalState.emailchanged) {
            alertRef?.current?.focus();
        }
        else {
            focusRef?.current?.focus();
        }
    }, [verificationCodeIncorrect, verificationCodeExpired, verificationCodeResent, globalState.emailchanged]);

    if (globalState?.email) {
        sessionStorage.setItem('userEmail', globalState?.email);
    } else {
        saveGlobalState({ email: sessionStorage.getItem('userEmail') });
    }

    const verifyEmail = async () => {
        if (verificationCodeIncorrectCount > 2) {
            navigate("/try-again", { replace: true });
        }

        //Validate inputs submitted via enter button
        if (!verificationCode || !verificationCodeValid) {
            return;
        }

        setCancel(false);
        setVerificationCodeResent(false);
        saveGlobalState({ emailchanged: false });

        const accesstoken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();

        try {
            await verifyVerificationCode(accesstoken, globalState?.email, verificationCode, idToken.sub);
            saveGlobalState({ emailVerified: true });

            // call to update user object, post verification
            await getAccessTokenSilently({ ignoreCache: true });

            // If user requested IPx:CL2 user must MFA after successful email OTP verification
            // This will redirect back to uplift once MFA'd
            if (globalState.acr.toUpperCase().includes('CL2')) {
                loginWithRedirect({
                    acr_values: 'urn:id.gov.au:tdif:acr:ip1:cl2',
                    redirectUri: `${window.location.origin}/callback-ip1`,
                });
            } else {
                // If user did not request CL2, redirect to uplift
                navigate("/ip-uplift", { replace: true });
            }
        }
        catch (error) {
            setVerificationCodeIncorrectCount(verificationCodeIncorrectCount + 1);

            if (error instanceof DocumentVerificationError) {
                if (error.failureMessage === "Wrong email or verification code.") {
                    setVerificationCodeIncorrect(true);
                    setVerificationCodeExpired(false);
                }
                else if (error.failureMessage === "The verification code has expired. Please try to login again.") {
                    setVerificationCodeIncorrect(false);
                    setVerificationCodeExpired(true);
                }
                else if (error.failureMessage === "You've reached the maximum number of attempts. Please try to login again.") {
                    navigate("/try-again", { replace: true });
                }

                setVerificationCodeResent(false);
            }
            else if (error instanceof DocumentAlreadyVerifiedError) {
                navigate("/email-error", { replace: true });
            }
            else {
                throw error;
            }
        }
    };

    const sendEmail = async () => {
        if (Constants.DEBUG) { console.log('Sending email OTP to ', globalState?.email); }
        if (!globalState.cancelEmailChange && globalState.emailchanged !== true) {
            const accesstoken = await getAccessTokenSilently({ ignoreCache: true });
            if (Constants.DEBUG) {
                console.log('%caccessToken: %o\nglobalState', 'color: #6f3;', accesstoken, globalState);
            }
            await sendVerificationCode(accesstoken, globalState?.email);
        }
    }

    function resendEmail() {
        if (attempts >= Constants.ALLOWED_OTP_ATTEMPTS) {
            navigate("/ip-uplift/too-many-resend-attempts", { replace: true });
        } else {
            sendEmail();
            setVerificationCodeResent(true);
            setCountDown(Constants.OTP_RESEND_WAIT_TIME ? Constants.OTP_RESEND_WAIT_TIME : 30);  //Default 30
            setAttempts(attempts + 1);
            setVerificationCode('');
            setVerificationCodeExpired(false);
            setVerificationCodeIncorrect(false);
            setVerificationCodeIncorrectCount(0);
            saveGlobalState({ emailchanged: false });
            setCancel(false);
        }
    }

    function cancelEmail() {
        if (!cancel) {
            setCancel(true);
        } else {
            // window.location.href = new RelyingPartyBuilder()
            //     .withRelyingParty(Constants.TMP_DLA_URI)
            //     .withReturnCode(2)
            //     .withAcValue(`IP1:${clValue}`)
            //     .build();
        navigate("/signup-paused", { replace: true });
        }
    }

    function changeEmail() {
        navigate('/change-email', { replace: true })
    }

    return (
        <>
            {isAuthenticated &&
                <form className="questions qg-forms-v2">
                    <div tabIndex={-1} ref={focusRef}></div>
                    <h1>Enter your email verification code</h1>

                    <div tabIndex={-1} ref={alertRef}>
                        {verificationCodeIncorrect ? <WarningAlert heading="Unable to verify code" message="We are unable to verify the verification code which was entered. Please re-enter your Verification Code, or select Resend to receive a new code." /> : null}
                        {verificationCodeExpired ? <WarningAlert heading="Code has expired" message={<>The verification code has expired. Please select <a onClick={resendEmail} className='tmr-link action-link'>'Resend the verification email'</a> to receive another Digital Identity verification code.</>} /> : null}
                        {globalState.emailchanged ? <SuccessAlert message="Thanks for entering your email address - you will need to validate the address by entering the validation code below." /> : null}
                        {verificationCodeResent && countDown > 0 ? <SuccessAlert heading="Verification Code resent" message="An email has been sent to the email address provided." /> : null}
                    </div>
                    <p>We have sent a Digital Identity verification code to the email:</p>

                    <Callout message={globalState?.email} showIcon={false} />

                    <ul className="questions">
                        <li>
                            <LabelInput id="verification-code" label="Digital Identity verification code"
                                inputValue={verificationCode}
                                isInputValid={verificationCodeValid}
                                setInputValue={setVerificationCode}
                                setInputValid={setVerifcationCodeValid}
                                validators={[patternValidator, codeLengthValidator]}
                                maxLength={6}
                                mode="number"
                            />
                        </li>
                    </ul>

                    <InfoAlert
                        message={
                            <>
                                <p>Check your Junk/Spam folders if the email does not appear in your inbox.</p>
                                <p>
                                    You can {countDown > 0 ? "resend the email" : <a onClick={resendEmail} className='tmr-link action-link'>resend the email</a>}
                                    {countDown > 0 ? (" in " + countDown + " second" + (countDown > 1 ? "s" : "")) : ""}, if you did not receive it.</p>
                            </>
                        }
                    />

                    <div className="list-style-none">
                        <li>
                            <p>
                                <a onClick={changeEmail} className='tmr-link action-link'>
                                    Change your email address
                                </a>, if the above email address is incorrect.
                            </p>
                        </li>
                    </div>

                    {cancel ? 
                        <WarningAlert 
                            heading="Sure you want to cancel?" 
                            message={<>
                                <p> If you cancel, your identity will not be verified. </p> 
                                <p> You will be able to verify your identity later by logging in using your email and password, and continuing this process.</p>
                                <p> Select Cancel if you wish to cancel this process.</p>
                            </>}
                        /> : null
                    }

                    <div style={{ position: 'relative', minHeight: 200 }}>
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id="submitButton" heading="Continue" action={verifyEmail} disabled={!verificationCode || !verificationCodeValid} />
                                    <SecondaryButton id="cancelButton" heading="Cancel" action={cancelEmail} />
                                </ul>
                            </li>
                        </ol>
                    </div>

                </form>
            }
        </>)
};

export default EnterEmailOneTimeCode;

import React from 'react'
import * as Constants from '../constants';

const Aside = () => (
  <aside id="qg-secondary-content">
    <div id="asides" className="qg-aside qg-tip" role="complementary">
      <h2>
        <span className="fa fa-lightbulb-o fa-2x" aria-hidden="true"></span>
        Need help?
      </h2>

      <p>
        Visit the <a href={Constants.CONTACT_US_URL} target="_blank" rel="noopener">Queensland Digital Identity webpage</a> to learn more about creating your QDI and troubleshoot any problems.
      </p>

    </div>
  </aside>
);

export default Aside;
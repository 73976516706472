import React from "react";
import BirthCertificateFields from "./birth-certificate-fields";
import BirthCertificateType from "./birth-certificate-type";

export const VariLengthBirthCertificateFields = (props : Omit<BirthCertificateType,'certificateNumberLengthFixed'|'registrationNumberLengthFixed'>) => {
    return (
        <BirthCertificateFields
            givenNamesMandatory={true} 
            certificateNumberMaxLength={9}
            certificateNumberLengthFixed={false}
            registrationNumberMaxLength={10}
            registrationNumberLengthFixed={false}
            {...props}
        />
    )
}

export default VariLengthBirthCertificateFields; 
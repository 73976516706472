import React from 'react';
import BaseButton from './base/base-button';

/**
 * A Primary Button, solid green.
 * 
 * @param id: id and name html attribute value
 * @param heading: bold short text to display next to icon. optional.
 * @param action: action to take when button is clicked
 * @param disabled: whether the button is disabled or actionable
 */
 type Props = {
    id:string,
    heading: string,
    action: (params:any) => any,
    disabled?:boolean,
    testId?: string,
};

function PrimaryButton({id, heading, action, disabled=false, testId} : Props) {
    let attributes:any = {};
    if (testId) {
        attributes.testId = testId;
    }
    return (
        <BaseButton id={id} heading={heading} action={action} type="btn-primary" disabled={disabled} {...attributes} />
    );
}

export default PrimaryButton;
import Validator from "./validator";

export class FlexibleDateValidator implements Validator {

    validate(value: any, setHint: (params: string) => any, label: string) {
        if(value.length > 0) {
            let year = (value) ? value.split('-')[0] : null;
            if (checkDateFormatInvalid(value) || checkDateInvalid(value)) {
                setHint(label +' is invalid');
                return false;
            }
            else if (year && (year > new Date().getFullYear() || year < 1900)) {
                setHint(label +` can only be between the year 1900-${new Date().getFullYear()}`);
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
}

function checkDateInvalid(userDate:string) {
    let valid = true;
    let dateParts = userDate.split('-');
    let month = Number(dateParts[1]); //yyyy-MM-dd
    let day = Number(dateParts[2]);
    let year = Number(dateParts[0]);

    if (day > 0 && month > 0 && year > 0) { // validate for dd/mm/yyyy
        let checkIsActualDate = new Date(year,month-1,day);
        
        if (month > 12 || month ==0 || day==0 || day >31 || (month-1) !== checkIsActualDate.getMonth()) {
            valid = false;
        }
        else if (isNaN(checkIsActualDate.getTime())) {
            valid = false;
        }
    } else if (month > 0 && year > 0) { // validate for mm/yyyy
        if (month > 12 || month ==0) {
            valid = false;
        }
    }

    return !valid;
}

/**
 * Human readable 3 tests. If all false then return false, otherwise true.
 * @param {*} userDate 
 */
function checkDateFormatInvalid(userDate:string) {
    let dmyTest = (/^(\d{1,4})-(\d{1,2})-(\d{1,2})$/.test(userDate));
    let myTest = (/^(\d{1,4})-(\d{1,2})-$/.test(userDate));
    let yTest = (/^(\d{1,4})--$/.test(userDate));
    let combined = !dmyTest && !myTest && !yTest;

    return combined;
}

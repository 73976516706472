import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import Product from "../../components/qdi/product";
import SelectForm from "../../components/qdi/form/select-form";
import { MandatoryValidator } from "../../utils/validation/validator";
import InfoAlert from "../../components/swe/info-alert";
import PrimaryButton from "../../components/swe/primary-button";
import SecondaryButton from "../../components/swe/secondary-button";
import ChangeOfNameCertificateForm from "../../components/qdi/form/change-of-name-certificate/change-of-name-certificate";
import WarningAlert from "../../components/swe/warning-alert";
import { AssociateQDIUserAccountBuilder, XBody, verifyNameChangeCertificate, DocumentVerificationError } from "../../services/x-api";
import MFAHandler from "../../utils/MFAHandler";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import * as Constants from "../../constants";
import { extractIpn, updateSessionTokenIpn } from "../../utils/helpers";
import useGlobalState from "../../hooks/useGlobalState";
import DocumentInvalidAlert from "../../components/qdi/alerts/document-invalid-alert";
import DocumentVerificationFailedAlert from "../../components/qdi/alerts/document-verification-failed-alert";
import DoBMismatchAlert from "../../components/qdi/alerts/dob-mismatch-alert";

export const ChangeOfNameCertificate = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
    const { globalState ,saveGlobalState } = useGlobalState();
    const [registrationState, setRegistrationState] = useState('');
    const [registrationStateValid, setRegistrationStateValid] = useState(true);
    const [isFormValid, setIsFormValid] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [counter, setCounter] = useState(0);
    const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
    const [dobMismatch, setDobMismatch] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const focusRef = useRef(null);

    let mandatoryValidator = new MandatoryValidator();

    //These 3 states requires CertNumber and RegNumber to be in type of number
    const statesRequireIdAsNumber = ['NSW', 'VIC', 'TAS'];

    useEffect(() => {
      (async () => {
  
        try {
          if (!isAuthenticated) {
              // Throw exception if user not authenticated
              throw new Error("User is not authenticated!");
          }
          await getAccessTokenSilently({ ignoreCache: true });
          const claims = await getIdTokenClaims();
  
          if (Constants.DEBUG) {
            console.log('%c[ChangeOfNameCertificate] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
              isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
          }
  
          const ipn = extractIpn(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
          if (Constants.DEBUG) { console.log('user claims  ipn ->', ipn); }
          const sessionToken = localStorage.getItem('session_token');
          saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
          saveGlobalState({ idState: JSON.parse(localStorage.getItem('state')!) });
          if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;
  

            // Check if user has completed this step
            if (!(globalState?.sessionToken?.ipn === "ip1p_nc" || 
                (globalState?.sessionToken?.ipn === "ip1p" && globalState?.sessionToken?.ipn_status === "nc")) || 
                (user && user.email_verified === false)) {
              if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step completed. Redirecting to uplift"); }
              navigate("/ip-uplift", { replace: true });
            }
        }
        catch (error) {
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        //   const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        //   window.location.href = rp;
        navigate("/unauthorised", { replace: true });
        }  
      })();
    }, []);

    useEffect(() => {
        if(dobMismatch || verificationFailed || documentInvalid) {
            focusRef?.current?.focus();
        }

        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }
    }, [dobMismatch, verificationFailed, documentInvalid]);

    const options = new Map<string, any>();
    options.set("ACT", <ChangeOfNameCertificateForm 
        state="ACT" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        familyNameMandatoryText="is required. If there is no Family name printed on the certificate, a hyphen must be entered into the Family name (new) field"
        givenNameMandatoryText="is required. If there is no Given name/s printed on the certificate, a hyphen must be entered into the Given name/s (new) field"
    />);
    options.set("NSW", <ChangeOfNameCertificateForm 
        state="NSW" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        givenNamesMandatory={false}
        registrationNumberMaxLength={7}
        certificateNumberMaxLength={11}
    />);
    options.set("NT", <ChangeOfNameCertificateForm 
        state="NT" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
    />);
    options.set("QLD", <ChangeOfNameCertificateForm 
        state="QLD" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        registrationIsDate={true}
        certificateNumberMaxLength={10}
        certificateNumberLengthFixed={true}
    />);
    options.set("SA", <ChangeOfNameCertificateForm 
        state="SA" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
    />);
    options.set("TAS", <ChangeOfNameCertificateForm 
        state="TAS" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        registrationNumberMaxLength={6}
        certificateNumberMaxLength={11}
        certificateNumberLengthFixed={true}
    />);
    options.set("VIC", <ChangeOfNameCertificateForm 
        state="VIC" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        givenNamesMandatory={false}
        certificateNumberMaxLength={12}
        familyNameMaxLength={80}
        givenNameMaxLength={80}
    />);
    options.set("WA", <ChangeOfNameCertificateForm 
        state="WA" 
        setFormFields={setFormFields} 
        setIsFormValid={setIsFormValid} 
        givenNamesMandatory={false}
        registrationNumberMaxLength={7}
        registrationNumberLengthFixed={true}
        certificateNumberMaxLength={11}
        certificateNumberLengthFixed={true}
    />);

    const verifyChangeOfNameCertificateForm = async () => {
        setAlertVisibility(false, false, false);
        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
            return;
        }

        try {
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims();

            let builder = new AssociateQDIUserAccountBuilder()
                .withUserId(idToken!.sub)
                .withVerifiedDocTypeCode("NC")
                .withIssuingAuthority(formFields.get('state'))
                .withFamilyName(formFields.get('familyName'))
                .withGivenName(formFields.get('givenNames'))
                .withBirthdate(formFields.get('dateOfBirth'));
            
            if (formFields.has('certificateNumber') && formFields.get('certificateNumber').length !== 0) {
                builder.withVerifiedDocIdentifier("Certificate Number", getCertificateNumber(formFields));
            }

            if (formFields.has('registrationValue') && formFields.get('registrationValue').length !== 0) {
                builder.withVerifiedDocIdentifier(formFields.get('registrationType'), getRegistrationValue(formFields));
            }
            
            let certificate = new XBody(builder.build());

            await verifyNameChangeCertificate(certificate, token);
            setCounter(0);

            // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
            // ipn_status not getting updated in token issue.
            const updatedSessionToken = updateSessionTokenIpn('ip2');
            saveGlobalState({ sessionToken: updatedSessionToken });

            navigate("/ip-uplift/success", { replace: true });
        } catch (error) {
            setCounter(counter + 1);
            if (error instanceof DocumentVerificationError) {
                if (error.failureCode === "N" && error.failureMessage === "Date of birth not match") {
                    setAlertVisibility(false, false, true);
                }
                else if (error.failureCode === "D") { // && "Invalid document used in EOI verification" == error.failureMessage) {
                    setAlertVisibility(false, true, false);
                }
                else if (error.failureMessage === "Document Temporarily Locked") {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                }
                else if (error.failureCode === "N" && error.failureMessage === "Name not match") {
                    globalState.nameDidnotMatch = true;
                    navigate("/ip-uplift/names-dont-match", { replace: true });
                }
                else if (error.failureCode === "N") {
                    setAlertVisibility(true, false, false);
                } else {
                    throw error;
                }
            }
            else {
                throw error;
            }
        }
    }

  function getCertificateNumber(formFields: any) {
    if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
      return +formFields.get('certificateNumber');
    } else {
      return formFields.get('certificateNumber');
    }
  }

  function getRegistrationValue(formFields: any) {
    if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
      if (formFields.get('registrationType') === "Registration Date") {
        return formFields.get('registrationValue');
      } else {
        return +formFields.get('registrationValue');
      }
    } else {
      return formFields.get('registrationValue');
    }
  }

    function setAlertVisibility(verifyFailed: boolean, docInvalid: boolean, dobMismtch: boolean){    
      setVerificationFailed(verifyFailed);
      setDocumentInvalid(docInvalid);
      setDobMismatch(dobMismtch);
    }

    function goBack () {
        navigate("/ip-uplift/name-change-options", { replace: true });
    }
    
    return (
        <>
            {isAuthenticated && 
                <div>
                    <div tabIndex={-1} ref={focusRef}></div>
                    <h1>Verify your name change</h1> 

                    {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel="Change of Name Certificate" /> : null}
                    {documentInvalid ? <DocumentInvalidAlert /> : null}
                    {dobMismatch ? <DoBMismatchAlert /> : null} 

                    <p>Enter your information, exactly as it appears on your Australian Change of Name Certificate.</p>
                    
                    <section className="row cards-identity qg-cards cards_equal-height">
                        <Product heading="Australian Change of Name Certificate" media={require('../../assets/images/Australian Name Change Certificate.png')} altText="QLD Drivers Licence" action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />
                    </section>

                    <SelectForm id="registration-state" label="Australian Change of Name Certificate registration state" 
                        inputValue={registrationState}
                        isInputValid={registrationStateValid}
                        setInputValue={setRegistrationState}
                        setInputValid={setRegistrationStateValid}
                        options={options}
                        noSelectionText="Please select a state"
                        validators={[mandatoryValidator]}
                        infoMessage={registrationState !== '' &&
                            <>
                                <InfoAlert message={<>If you experience any trouble locating the information on your document, visit the <a target="_blank" className="tmr-link" href="https://www.mygovid.gov.au/verify-a-change-in-name-change-of-name-certificate">myGovID website</a> for assistance.</>} />
                            </>
                        }
                    />

                    {registrationState !== '' &&
                        <WarningAlert heading="Verifying your identity" message={<><p>As part of verifying your identity, we need to check the details you have provided with the Issuer/Official record holder.</p><p>By selecting Continue, you give us your consent to check the details with the Issuer/Official record holder for the purpose of confirming your identity.</p></>} />
                    }

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id="continueButton" heading="Continue" action={verifyChangeOfNameCertificateForm} disabled={!isFormValid || registrationState === ''} />
                                    <SecondaryButton id="backButton" heading="Back" action={goBack} />
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    );
};

export default ChangeOfNameCertificate;
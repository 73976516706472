import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ImmiCard from "../../components/qdi/form/immicard-form";
import { createElement } from "react";
import Product from "../../components/qdi/product";
import { verifyImmiCard } from "../../services/x-api";
import { extractIpn, determineCorrectIpnValue } from "../../utils/helpers";
import * as Constants from "../../constants";
import useGlobalState from "../../hooks/useGlobalState";
import { useNavigate, useLocation } from "react-router-dom";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";

export const ImmiCardForm = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const imageUrl = require('../../assets/images/Immigration Status or Australian Migration Status.png');
    const { globalState, saveGlobalState } = useGlobalState();
    const { state } = useLocation();
    const ipnLevel = state && state.ipnLevel;
    const dlaUser = state && state.dlaUser;
    const navigate = useNavigate();

    
    useEffect(() => {
        (async () => {
    
          try {
            if (!isAuthenticated) {
                // Throw exception if user not authenticated
                throw new Error("User is not authenticated!");
            }
            await getAccessTokenSilently({ ignoreCache: true });
            const claims = await getIdTokenClaims();
            const claimsIpnFromAcr = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
            const ipn = determineCorrectIpnValue(claimsIpnFromAcr!, user!['http://oauth.tmr.qld.gov.au'].ipn);
      
            if (Constants.DEBUG) {
              console.log('users ipn ->', ipn);
              console.log('%c[CitizenshipCertificateForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
            }
    
            const sessionToken = localStorage.getItem('session_token');
            saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;
    
            // Check if user should be here
            if ((!dlaUser && globalState.sessionToken.ipn !== ipnLevel)
                || (dlaUser && (dlaUser.result === 'Eligible' || dlaUser.result === 'Not eligible'))
                    || (dlaUser && dlaUser.result === 'TMR document required')
                        || (user && user.email_verified === false)) {

              if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
              navigate("/ip-uplift", { replace: true });
            }
          }
          catch (error) {
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
            // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
            // window.location.href = rp;
            navigate("/unauthorised", { replace: true });
          }
    
        })();
      }, []);

    return (
        <>
            {isAuthenticated && 
                <div>
                    {/* <h1>Verify your {ipnLevel === "ip1" ? "first" : "second"} credential</h1> */}
                    <h1>Verify your {ipnLevel === "ip1" ? "first" : ipnLevel === "ip1p" ? "second" : ""} credential</h1>

                    <ImmiCard 
                        id="ImmiCard"
                        instructions={createElement('p', {}, 'Enter your information exactly as it appears on your ImmiCard.')}
                        product={createElement(Product, 
                            { heading:"ImmiCard - Evidence of Immigration Status or Australian Migration Status",
                              media:imageUrl,
                              selected:true,
                              disabled:true})}
                        backNavigateTo={"/ip-uplift/verify-your-identity"}
                        nextNavigateTo='/ip-uplift'
                        typeCode="IM"
                        identifierType="ImmiCard Number"
                        action={verifyImmiCard}
                        allowSingleName={true}
                        ipnLevel={ipnLevel}
                        dlaUser={dlaUser}
                    />
                </div>
            }
        </>
    );
};

export default ImmiCardForm;
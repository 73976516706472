import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormTypeProps from "./Form-type";
import InfoAlert from "../../swe/info-alert";
import PrimaryButton from "../../swe/primary-button";
import SecondaryButton from "../../swe/secondary-button";
import LabelInput from "../../swe/base/label-input";
import LabelDateInput from "../../swe/base/label-date-input";
import VerifyYourIdentityConsentAlert from "../alerts/verifying-identity-consent-alert";
import { ImmiCardNumberValidator, MandatoryValidator, MaximumLengthValidator, NonSpaceInputValidator, NotFutureDateValidator, NumbersNotAllowedValidator, PatternValidator } from "../../../utils/validation/validator";
import { FlexibleDateValidator } from "../../../utils/validation/FlexibleDateValidator";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../../../constants';
import { AssociateQDIUserAccountBuilder, DocumentAlreadyVerifiedError, DocumentVerificationError, XBody } from "../../../services/x-api";
import { formatDateString, updateLocalStorageSessionTokenIpnAndStatus, updateSessionTokenIpn } from "../../../utils/helpers";
import DocumentVerificationFailedAlert from "../alerts/document-verification-failed-alert";
import DocumentAlreadyVerifiedAlert from "../alerts/document-already-verified-alert";
import DocumentInvalidAlert from "../alerts/document-invalid-alert";
import DoBMismatchAlert from "../alerts/dob-mismatch-alert";
import useGlobalState from "../../../hooks/useGlobalState";
interface ImmiCardFormTypeProps extends FormTypeProps {
    allowSingleName?: boolean
}

export const ImmiCard = (props: ImmiCardFormTypeProps) => {
    const navigate = useNavigate();
    const focusRef = useRef(null);
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const dlaUser = props.dlaUser;

    const [isContinueDisabled, setIsContinueDisabled] = useState(true);
    const [immicardNumber, setImmicardNumber] = useState('');
    const [immicardNumberValid, setImmicardNumberValid] = useState(true);
    const [familyName, setFamilyName] = useState('');
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [givenNames, setGivenNames] = useState('');
    const [givenNamesValid, setGivenNamesValid] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const [dobMismatch, setDobMismatch] = useState(false);
    const [unexpectedError, setUnexpectedError] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isSingleName, setIsSingleName] = useState(false);

    // validators
    let mandatoryValidator = new MandatoryValidator();
    let gnMandatoryValidator = new MandatoryValidator("is required. If there is no ### printed on the card, a hyphen must be entered into the ### field");
    let immicardNumberPatternValidator = new ImmiCardNumberValidator();
    let nameLengthValidator = new MaximumLengthValidator(49);
    let namePatternValidator = new PatternValidator(/[^a-zA-Z0-9'’\- ]/, "Only the following special characters and symbols are allowed ' -");
    let dateOfBirthValidator = new FlexibleDateValidator();
    let futureDateValidator = new NotFutureDateValidator();
    let allSpacesValidator = new NonSpaceInputValidator();
    let numbersNotAllowedValidator = new NumbersNotAllowedValidator();

    useEffect(() => {
        focusRef?.current?.focus();
    },[]);

    useEffect(() => {
        if(verificationFailed || documentAlreadyVerified || documentInvalid || dobMismatch || unexpectedError) {
            focusRef?.current?.focus();
        }

        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }
    }, [verificationFailed, documentAlreadyVerified, documentInvalid, dobMismatch, unexpectedError]);

    useEffect(() => {
        const isInvalid = validateIsContinueDisabled();
        setIsContinueDisabled(isInvalid);
    }, [immicardNumberValid, familyNameValid, givenNamesValid, dateOfBirthValid, immicardNumber, familyName, givenNames, dateOfBirth]);

    const validateIsContinueDisabled = () => {
        let areAllFieldsValid = (immicardNumberValid && familyNameValid && (props.allowSingleName || givenNamesValid) && dateOfBirthValid);
        let areAllMandatoryFieldsPopulated = (immicardNumber.length > 0 && familyName.length > 0 && givenNames.length > 0 && dateOfBirth.length > 0);

        return (!areAllFieldsValid || !areAllMandatoryFieldsPopulated);
    }
    
    const verifyImmiCard = async () => {
        console.group("async verifyImmiCard");
        setAlertVisibility(false, false, false, false); //Reset alert visibility

        if(counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }

        try {
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims();
            if(Constants.DEBUG) { console.log(token, idToken); }

            let builder = new AssociateQDIUserAccountBuilder()
                .withUserId(idToken!.sub)
                .withFamilyName(familyName.trim())
                .withGivenName(givenNames.trim())
                .withBirthdate(formatDateString(dateOfBirth))
                .withVerifiedDocTypeCode("IM")
                .withVerifiedDocIdentifier("ImmiCard Number", immicardNumber);

            let certificate = new XBody(builder.build());

            await props.action(certificate, token);
            setCounter(0);

            if(props.ipnLevel === 'ip1p'){
                // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
                // ipn_status not getting updated in token issue.
                const updatedSessionToken = updateSessionTokenIpn('ip2');
                saveGlobalState({ sessionToken: updatedSessionToken });

                //If this user is uplifting for the DL, record second doc so we
                //can remove it from the available list if third doc required
                if (dlaUser) dlaUser.secondDoc = props.typeCode;
            }

            navigate("/ip-uplift", { replace: true });        
        } catch (error) {
            if (Constants.DEBUG) { console.log(error); }
            setCounter(counter + 1);
            if(error instanceof DocumentVerificationError) {
                // date of birth mismatch test
                if (error.failureCode === "N" && error.failureMessage === "Date of birth not match") {
                    setAlertVisibility(false, false, false, true);
                }
                else if ("N" == error.failureCode && "Name not match" == error.failureMessage) {
                    if (props.ipnLevel === "ip2") {
                        // DLA user where name on verified doc doesnt match 1st and second doc
                        navigate("/ip-uplift/names-not-match-hardstop", { replace: true });
                    } else {
                        // Setting localStorage.sessionToken.ipn = "ip1p_nc" as a temp fix for
                        // ipn_status not getting updated in token issue.
                        updateLocalStorageSessionTokenIpnAndStatus("ip1p_nc", "nc");
                        navigate("/ip-uplift/name-change-options", { replace: true });
                    }
                }
                else if (error.failureCode === "D") {
                    setAlertVisibility(false, true, false, false)
                } 
                else if (error.failureCode === "Document Temporarily Locked") {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                } 
                else if (error.failureCode === "N"){
                    setAlertVisibility(true, false, false, false);
                } 
                else {
                    setUnexpectedError(true);
                    throw error;
                }
            } else if (error instanceof DocumentAlreadyVerifiedError) {
                setAlertVisibility(false, false, true, false);
            } else {
                setUnexpectedError(true);
                throw error;
            }
            
        }

        console.groupEnd();
    }

    const setAlertVisibility = (verifyFailed: boolean, docInvalid: boolean, docAlreadyVerified: boolean, dobMismtch: boolean) => {    
      setVerificationFailed(verifyFailed);
      setDocumentInvalid(docInvalid);
      setDocumentAlreadyVerified(docAlreadyVerified);
      setDobMismatch(dobMismtch);
    }

    const navigateBackTo = () => {
        navigate(props.backNavigateTo, { replace: true, state: { ipnLevel: props.ipnLevel, dlaUser } });
    }
    
    const onSingleNameCheckBoxChange = (checked: boolean) => {
        setFamilyNameValid(true);
        setGivenNamesValid(true);
        setIsSingleName(checked);
        setFamilyName('');
        if(checked) {
            setGivenNames('-');
        } else {
            setGivenNames('');
        }
    }
    
    return(
        <>
            <div tabIndex={-1} ref={focusRef}></div>
            {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel={props.id} /> : null }
            {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null }
            {documentInvalid ? <DocumentInvalidAlert /> : null }
            {dobMismatch ? <DoBMismatchAlert /> : null }

            {props.instructions}

            <section className="row cards-identity qg-cards cards_equal-height">
                {props.product}
            </section>

            <InfoAlert message={<>If you experience any trouble locating the information on your document, visit the <a href="https://www.mygovid.gov.au/verifying-your-identity#IM" target="_blank" rel="noopener noreferrer">myGovID website</a> for assistance.</>} />

            <form className="qg-forms-v2">
                <ol className="questions">
                    <li>
                        <LabelInput 
                            id="immi-card-number"
                            label="ImmiCard Number"
                            inputValue={immicardNumber}
                            isInputValid={immicardNumberValid}
                            setInputValue={setImmicardNumber}
                            setInputValid={setImmicardNumberValid}
                            mandatory={true}
                            validators={[
                                mandatoryValidator,
                                allSpacesValidator, 
                                immicardNumberPatternValidator
                            ]}
                        />
                    </li>
                    
                    {props.allowSingleName &&
                    <>
                        <li className="no-top-bottom-padding">
                            <legend><h3>Your details</h3></legend>
                        </li>
                        <li className="no-top-bottom-padding">
                            <ul className="choices qg-forms-v2__checkbox">
                                <li>
                                    <input 
                                        type="checkbox" 
                                        id="checked-details" 
                                        autoComplete="off" 
                                        area-checked="false"
                                        checked={isSingleName}
                                        onChange={(e)=> onSingleNameCheckBoxChange(e.target.checked)}
                                    />
                                    <label htmlFor="checked-details">
                                        <span>
                                            <p>I only have a single name</p>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </li>
                    </>}
                    {props.allowSingleName && isSingleName ? 
                    <>
                        <li className="no-top-bottom-padding">
                            <small className="hint">Please enter your name into the Name field</small>
                        </li>
                        <li>
                            <LabelInput
                                id="name"
                                label="Name"
                                inputValue={familyName}
                                isInputValid={familyNameValid}
                                setInputValue={setFamilyName}
                                setInputValid={setFamilyNameValid}
                                validators={[mandatoryValidator, allSpacesValidator, nameLengthValidator, namePatternValidator, numbersNotAllowedValidator]}
                            />
                        </li>
                    </> :
                    <>
                        <li>
                            <LabelInput 
                                id="family-name" 
                                label="Family name" 
                                inputValue={familyName}
                                isInputValid={familyNameValid}
                                setInputValue={setFamilyName}
                                setInputValid={setFamilyNameValid}
                                mandatory={true}
                                validators={[
                                    mandatoryValidator, 
                                    nameLengthValidator, 
                                    namePatternValidator,
                                    allSpacesValidator,
                                    numbersNotAllowedValidator
                                ]}
                            />
                        </li>
                        <li>
                            <LabelInput 
                                id="given-names" 
                                label="Given name/s" 
                                inputValue={givenNames}
                                isInputValid={givenNamesValid}
                                setInputValue={setGivenNames}
                                setInputValid={setGivenNamesValid}
                                mandatory={true}
                                validators={[
                                    gnMandatoryValidator, 
                                    nameLengthValidator, 
                                    namePatternValidator,
                                    allSpacesValidator,
                                    numbersNotAllowedValidator
                                ]}
                            />
                        </li>
                    </>}
                    <li>
                        <LabelDateInput 
                            id="date-of-birth" 
                            label="Date of birth" 
                            inputValue={dateOfBirth}
                            isInputValid={dateOfBirthValid}
                            setInputValue={setDateOfBirth}
                            setInputValid={setDateOfBirthValid}
                            validators={[
                                mandatoryValidator, 
                                dateOfBirthValidator, 
                                futureDateValidator
                            ]}
                            flexibleDate={true}
                        />
                    </li>
                </ol>
            </form>

            <VerifyYourIdentityConsentAlert />

            <div className="my-3">
                <ol className="questions">
                    <li>
                        <ul className="actions">
                            <PrimaryButton id="continueButton" heading="Continue" action={verifyImmiCard} disabled={isContinueDisabled} />
                            <SecondaryButton id="backButton" heading="Back" action={navigateBackTo} />
                        </ul>
                    </li>
                </ol>
            </div>
        </>
    );
};

export default ImmiCard;
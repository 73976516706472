import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import CriticalAlert from "../components/swe/critical-alert";
import { DEBUG } from "../constants";
import * as Constants from "../constants";

const DOBNotMatchHardStop = () => {

  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      if (DEBUG) { console.log('Hard stop! Third doc, DOB not match. Logging user out of QDI session.'); }
      logout({ localOnly: true });
      localStorage.clear();
      sessionStorage.clear();
    })();
  }, [])

  return (
    <>
      <div>
        <h1>Queensland Digital Identity</h1>
        <div tabIndex={-1}>

          <CriticalAlert heading="Date of birth does not match" message={
            <div>
              <p>All of your credentials have successfully been verified, however your date of birth is different between your TMR credential and what you have provided previously.</p>
              <p>Please <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity</p>
            </div>
          } />

        </div>
      </div>
    </>
  )
};

export default DOBNotMatchHardStop;
import React, {useState} from "react";
import AttributeFactory from "../../../utils/AttributeFactory";
import InputTypeProps from "./input-type";
import Label from "./Label";

interface LabelInputTypeProps extends InputTypeProps {
    labelClassName?: string,
}

/**
 * Label and input text field.
 * 
 * @param props 
 * @returns 
 */
export const LabelInput = (props : LabelInputTypeProps) => {
    const [hint,setHint] = useState('');

    let attributes = AttributeFactory.build(props, setHint);

    return (
        <>
            {!props.hideLabel && <Label id={props.id} label={props.label} mandatory={props.mandatory} persistentHint={props.persistentHint && props.hintPosition==='above' ? props.persistentHint:null} className={props.labelClassName} />}
            {!props.isInputValid? <em className="error-label">{(props.inputHint) ? props.inputHint : hint}</em> : null }
            <input {...attributes} />
            {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
        </>
    );
}

LabelInput.defaultProps = {
    hintPosition: "above"
}

export default LabelInput;

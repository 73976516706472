import React from "react";
import MarriageCertificateForm from "./marriage-certificate";
import MarriageCertificateType from "./marriage-certificate-type";

export const ACTMarriageCertificateForm = (props: Omit<MarriageCertificateType, 'state' | 'registrationIsDate'>) => {
  return (
    <MarriageCertificateForm
      state="ACT"
      familyNameMessage="is required. If there is no ### printed on the certificate, a hyphen must be entered into the ### field"
      givenNamesMessage="is required. If there is no ### printed on the certificate, a hyphen must be entered into the ### field"
      certificateNumberMaxLength={9}
      registrationNumberMaxLength={10}
      registrationIsDate={false}
      {...props}
    />
  )
}

export default ACTMarriageCertificateForm; 
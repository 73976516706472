import React from "react";

type Props = {
    id: string,
    label: string,
    mandatory?:boolean,
    persistentHint?: string,
    className?: string,
}

export const Label = ({id, label, mandatory=true, persistentHint, className}: Props) => {

    return (
        <>
            <label htmlFor={id} id={id+"Label"} className={className}>
                <span className="label">{ label}</span>
                { mandatory ? <abbr className="required frminput" title="(required)">*</abbr> : null }
                {(persistentHint)? <small className="hint">{ persistentHint }</small> : null }
            </label>
        </>
    )
}

export default Label;

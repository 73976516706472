import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import CriticalAlert from "../components/swe/critical-alert";
import * as Constants from "../constants";

const FamilyNameNotMatchHardStop = () => {

  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      if (Constants.DEBUG) { console.log('Hard stop! Third doc, names not match. Logging user out of QDI session.'); }
      logout({ localOnly: true });
      localStorage.clear();
      sessionStorage.clear();
    })();
  }, [])

  return (
    <>
      <div>
        <h1>Queensland Digital Identity</h1>
        <div tabIndex={-1}>

          <CriticalAlert heading="Family names do not match" message={
            <div>
              <p>Your credentials have been successfully verified, however the family name on your credentials do not match.</p>
              <p>In order to continue verifying your identity, you will need to <a href={Constants.CONTACT_US_URL}>contact us</a>, so that we can complete the process with you.</p>
            </div>
          } />

        </div>
      </div>
    </>
  )
};

export default FamilyNameNotMatchHardStop;
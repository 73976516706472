import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../../hooks/useGlobalState";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import * as Constants from "../../constants";

import LicenceDocumentForm from "../../components/qdi/form/licence-document-form";
import Product from "../../components/qdi/product";
import { verifyPIC } from "../../services/x-api";
import {extractIpn, determineCorrectIpnValue} from "../../utils/helpers";

export const PhotoIdForm = () => {
  const navigate = useNavigate();
  const { globalState, saveGlobalState } = useGlobalState();
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();    
  const { state } = useLocation();
  const ipnLevel = state && state.ipnLevel;
  const dlaUser = state && state.dlaUser;

  useEffect(() => {
    (async () => {

      try {
        if (!isAuthenticated) {
            // Throw exception if user not authenticated
            throw new Error("User is not authenticated!");
        }
        await getAccessTokenSilently({ ignoreCache: true });
        const claims = await getIdTokenClaims();
        const claimsIpnFromAcr = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
        const ipn = determineCorrectIpnValue(claimsIpnFromAcr, user['http://oauth.tmr.qld.gov.au'].ipn);

        if (Constants.DEBUG) {
          console.log('users ipn ->', ipn);
          console.log('%c[PhotoIdForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
            isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
        }

        saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
        if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

        // Check if user should be here
        let mfaAvailable = globalState.userCompletedMFA;
        if (typeof mfaAvailable === 'undefined'
          || (!dlaUser && globalState.sessionToken.ipn !== ipnLevel)
            || (dlaUser && (dlaUser.result === 'Eligible' || dlaUser.result === 'Not eligible'))
              || (dlaUser && dlaUser.result === 'Third document required')
                || (user && user.email_verified === false)) {

          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
          navigate("/ip-uplift", { replace: true });
        }
      }
      catch (error) {
        if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        // window.location.href = rp;
        navigate("/unauthorised", { replace: true });
      }
    })();
  }, []);

  return (
    <>
      {isAuthenticated && 
        <div>
          {/* <h1>Verify your {ipnLevel === "ip1" ? "first" : "second"} credential</h1>  */}
          <h1>Verify your {ipnLevel === "ip1" ? "first" : ipnLevel === "ip1p" ? "second" : dlaUser && dlaUser.result === 'TMR document required' ? "TMR" : ""} credential</h1>

          <LicenceDocumentForm
            id="Queensland Photo Identification Card or Adult Proof of Age Card"
            instructions={<p>Enter your information, exactly as it appears on your Queensland Photo Identification Card or Adult Proof of Age Card.</p>}
            product={<Product heading="Queensland Photo Identification Card or Adult Proof of Age Card" media={require('../../assets/images/photoidentification.png')} altText="QLD Photo Identification or Adult Proof of Age Card" action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />}
            backNavigateTo="/ip-uplift/verify-your-identity"
            nextNavigateTo='/ip-uplift'
            typeCode="PIC"
            identifierType="Card Number"
            action={verifyPIC}
            ipnLevel={ipnLevel}
            dlaUser={dlaUser}
          />
        </div>
      }
    </>)
};

export default PhotoIdForm;
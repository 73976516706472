import React, { useEffect, createElement } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Product from "../../components/qdi/product";
import CitizenshipForm from "../../components/qdi/form/citizenship-form";
import { verifyCitizenshipCertificate } from "../../services/x-api";
import * as Constants from "../../constants";
import useGlobalState from "../../hooks/useGlobalState";
import { extractIpn, determineCorrectIpnValue } from "../../utils/helpers";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";

export const CitizenshipCertificateForm = () => {
  const navigate = useNavigate();
  const { globalState, saveGlobalState } = useGlobalState();
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const imageURL = require('../../assets/images/Australian Citizenship Certificate or Citizenship of Descent.png');
  const { state } = useLocation();
  const ipnLevel = state && state.ipnLevel;
  const dlaUser = state && state.dlaUser;


  window.sessionStorage.setItem("QDI-IPE", "IP1P:CL1");

  useEffect(() => {
    (async () => {

      try {
        if (!isAuthenticated) {
          // Throw exception if user not authenticated
          throw new Error("User is not authenticated!");
        }
        await getAccessTokenSilently({ ignoreCache: true });
        const claims = await getIdTokenClaims();
        const claimsIpnFromAcr = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
        const ipn = determineCorrectIpnValue(claimsIpnFromAcr!, user!['http://oauth.tmr.qld.gov.au'].ipn);

        if (Constants.DEBUG) {
          console.log('users ipn ->', ipn);
          console.log('%c[CitizenshipCertificateForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
            isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
        }

        const sessionToken = localStorage.getItem('session_token');
        saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
        if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

        // Check if user should be here
        if ((!dlaUser && globalState.sessionToken.ipn !== ipnLevel)
          || (dlaUser && (dlaUser.result === 'Eligible' || dlaUser.result === 'Not eligible'))
            || (dlaUser && dlaUser.result === 'TMR document required')
              || (user && user.email_verified === false)) {
                
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
          navigate("/ip-uplift", { replace: true });
        }
      }
      catch (error) {
        if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        // window.location.href = rp;
        navigate("/unauthorised", { replace: true });
      }

    })();
  }, []);

  return (
    <>
      {isAuthenticated &&
        <div>
          <h1>Verify your {ipnLevel === "ip1" ? "first" : ipnLevel === "ip1p" ? "second" : ""} credential</h1>

          <CitizenshipForm
            id="Australian Citizenship Certificate or Australian Citizenship of Descent"
            instructions={createElement('p', {}, 'Enter your information exactly as it appears on your Australian Citizenship Certificate or Australian Citizenship of Descent.')}
            product={createElement(Product,
              {
                heading: "Australian Citizenship Certificate or Australian Citizenship of Descent",
                media: imageURL,
                selected: true,
                disabled: true
              })}
            backNavigateTo="/ip-uplift/verify-your-identity"
            nextNavigateTo='/ip-uplift'
            typeCode="CC"
            identifierType="Stock/Evidence Number"
            action={verifyCitizenshipCertificate}
            ipnLevel={ipnLevel}
            dlaUser={dlaUser}
          />
        </div>
      }
    </>
  );

};

export default CitizenshipCertificateForm;
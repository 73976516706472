import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../../hooks/useGlobalState";
import * as Constants from "../../constants";
import { extractIpn, determineCorrectIpnValue } from "../../utils/helpers";
import Product from "../../components/qdi/product";
import SecondaryButton from "../../components/swe/secondary-button";


export const MedicareCardSelectionPage = () => {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);
    const { state } = useLocation();
    const ipnLevel = state && state.ipnLevel;

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const claimsIpnFromAcr = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
                const ipn = determineCorrectIpnValue(claimsIpnFromAcr!, user!['http://oauth.tmr.qld.gov.au'].ipn);
      
                if (Constants.DEBUG) {
                    console.log('users ipn ->', ipn);
                    console.log('%c[MedicareCardSelectionPage] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined'
                    || globalState.dlaUser
                        || globalState.sessionToken.ipn !== "ip1p"
                            || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                // window.location.href = rp;
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []);

    useEffect(() => {
        focusRef?.current?.focus();
    }, []);

    const displayMedicareCardForm = ( cardType: string ) => {
        navigate("/ip-uplift/verify-your-identity/medicare-card-form", { replace: true, state: { mdtype: cardType, ipnLevel: ipnLevel} });
    }

    function goBack() {
      navigate("/ip-uplift/verify-your-identity", { replace: true, state: {ipnLevel: ipnLevel} });
    }


    return (
    <>
        {isAuthenticated && 
            <div>
                <h1>Verify your second credential</h1>
                <div tabIndex={-1} ref={focusRef}></div>

                <p>Please select the Medicare card type that you have from the list below:</p>
                
                <section className="row cards-identity qg-cards">
                    <Product
                        heading="Australian resident Medicare card"
                        media={require('../../assets/images/MedicareCardGreen.png')}
                        action={() => displayMedicareCardForm('G')}
                    />
                    <Product
                        heading="Australian Medicare Reciprocal Health Care card"
                        media={require('../../assets/images/MedicareCardYellow.png')}
                        action={() => displayMedicareCardForm('Y')}
                    />
                    <Product
                        heading="Australian Medicare Interim card"
                        media={require('../../assets/images/MedicareCardBlue.png')}
                        action={() => displayMedicareCardForm('B')}
                    />
                </section>

                <div className="my-3">
                    <ol className="questions">
                        <li>
                        <ul className='actions'>
                            <SecondaryButton id="backButton" heading="Back" action={goBack} />
                        </ul>
                        </li>
                    </ol>
                </div>

            </div>
        }
    </>
    );
}

export default MedicareCardSelectionPage;
import React, { useState } from 'react';

const AppContext = React.createContext();

const defaultGlobalState = {
    relyingParty:'',setRelyingParty:()=>{},
    token:null,setToken:()=>{}
};

export const AppProvider = ({ children, globalState }) => {
    const [currentGlobalState, setCurrentGlobalState] = useState(
    globalState || defaultGlobalState
    );

    const saveGlobalState = (values) => {
        let tmp = currentGlobalState;
        Object.assign(tmp, values);
        setCurrentGlobalState(tmp);
    };

    return (
        <AppContext.Provider value={{ globalState: currentGlobalState, saveGlobalState }}>
            {children}
        </AppContext.Provider>
    );
};

export const AppConsumer = AppContext.Consumer;

export default AppContext;
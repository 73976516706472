import WarningAlert from "../../swe/warning-alert";

/**
 * Orange clour information alert which informs user that by selecting continue, user is consenting to use the provided information.
 * 
 */

export const VerifyYourIdentityConsentAlert = () => {
    return (
        <WarningAlert 
            heading="Verifying your identity" 
            message={<><p>As part of verifying your identity, we need to check the details you have provided with the Issuer/Official record holder.</p><p>By selecting Continue, you give us your consent to check the details with the Issuer/Official record holder for the purpose of confirming your identity.</p></>} 
        />
    );
}

export default VerifyYourIdentityConsentAlert;
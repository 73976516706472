import React from "react";
import BirthCertificateFields from "./birth-certificate-fields";
import BirthCertificateType from "./birth-certificate-type";

export const RegDateBirthCertificateFields = (props : Omit<BirthCertificateType,'registrationIsDate'>) => {
    return (
        <BirthCertificateFields
            registrationIsDate={true}
            {...props}
        />
    )
}

export default RegDateBirthCertificateFields; 
import React, { useState, useEffect } from "react";
import LabelDateInput from "../../../swe/base/label-date-input";
import LabelInput from "../../../swe/base/label-input";
import { MandatoryValidator, LengthRangeValidator, PatternValidator, DateRangeValidator, NotFutureDateValidator,
    MaximumLengthValidator, NumbersOnlyValidator, EitherInputMandatoryValidator, LeadingZeroValidator,
    DateOnOrAfterDateValidator, IsValidDateValidator, NonSpaceInputValidator, FixedLengthValidatorUnlessOtherInput,
    NumbersNotAllowedValidator
} from "../../../../utils/validation/validator";
import { CheckOtherDateOnOrAfterDateValidator } from "../../../../utils/validation/CheckOtherDateOnOrAfterDateValidator";
import MarriageCertificateType from "./marriage-certificate-type";
import { LabelDateInputValidator } from "../../../../utils/validation/LabelDateInputValidator";
import { FlexibleDateValidator } from "../../../../utils/validation/FlexibleDateValidator";

export const MarriageCertificateForm = (props : MarriageCertificateType) => {

    const [certificateNumber, setCertificateNumber] = useState('');
    const [certificateNumberValid, setCertificateNumberValid] = useState(true);

    const [familyName1, setFamilyName1] = useState('');
    const [familyNameValid1, setFamilyNameValid1] = useState(true);
    const [givenNames1, setGivenNames1] = useState('');
    const [givenNamesValid1, setGivenNamesValid1] = useState(true);

    const [familyName2, setFamilyName2] = useState('');
    const [familyNameValid2, setFamilyNameValid2] = useState(true);
    const [givenNames2, setGivenNames2] = useState('');
    const [givenNamesValid2, setGivenNamesValid2] = useState(true);

    const [dateOfEvent, setDateOfEvent] = useState('');
    const [dateOfEventValid, setDateOfEventValid] = useState(true);

    const [registrationValue, setRegistrationValue] = useState('');
    const [registrationValueValid, setRegistrationValueValid] = useState(true);
    const [registrationValueHint, setRegistrationValueHint] = useState('');
    
    const [regValueSelected, setRegValueSelected] = useState(false);
    const [certNoSelected, setCertNoSelected] = useState(false);

    let mandatoryValidator = new MandatoryValidator();
    let mandatoryFNCustomMessageValidator = new MandatoryValidator(props.familyNameMessage);
    let mandatoryGNCustomMessageValidator = new MandatoryValidator(props.givenNamesMessage);
    let familyNameRangeValidator = new LengthRangeValidator(1,props.familyNameMaxLength!);
    let givenNameRangeValidator = new LengthRangeValidator(1,props.givenNameMaxLength!);
    let patternValidatorForName = new PatternValidator(props.nameFieldsCharsAllowedPattern!, `The only special characters and symbols allowed are ${props.nameFieldsCharsAllowedTrailingHint}`);
    let flexibleDateValidator = new FlexibleDateValidator();
    let futureDateValidator = new NotFutureDateValidator();    
    let dateInputValidator = new LabelDateInputValidator();
    let certificateNumberLengthValidator = props.certificateNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.certificateNumberMaxLength!, true, registrationValue) : new MaximumLengthValidator(props.certificateNumberMaxLength!,true);
    let certMaximumLengthValidator = new MaximumLengthValidator(props.certificateNumberMaxLength!, true);
    let registrationNumberLengthValidator = props.registrationNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.registrationNumberMaxLength!, true, registrationValue) : new MaximumLengthValidator(props.registrationNumberMaxLength!,true);
    let registrationValueLengthValidator = new MaximumLengthValidator(props.registrationNumberMaxLength!,true);
    let numbersOnlyValidator = new NumbersOnlyValidator();
    let allSpacesValidator = new NonSpaceInputValidator();
    let mustHaveCertificateNumberIfRegistrationDateEmptyValidator = new EitherInputMandatoryValidator(registrationValue, setRegistrationValueValid, `Certificate Number is required if you do not have a Registration ${props.registrationIsDate?'Date':'Number'}.`);
    let mustHaveRegistrationValueIfCertificateNumberEmptyValidator =  new EitherInputMandatoryValidator(certificateNumber, setCertificateNumberValid, `Registration ${props.registrationIsDate?'Date':'Number'} is required if you do not have a Certificate Number.`);
    let registrationDateMustBeOnOrAfterDateOfEventValidator = new DateOnOrAfterDateValidator(dateOfEvent, "Date of event");
    let registrationDateCheckWhenDateOfEventChangesValidator = new CheckOtherDateOnOrAfterDateValidator(registrationValue, setRegistrationValueValid, 'Registration Date must be on or after the Date of event', setRegistrationValueHint);
    let leadingZeroValidator = new LeadingZeroValidator(props.validateLeadingZeros!);    
    let numbersNotAllowedValidator = new NumbersNotAllowedValidator();

    let familyNameValidators:any = [familyNameRangeValidator, patternValidatorForName, allSpacesValidator, numbersNotAllowedValidator];
    let givenNameValidators:any = [givenNameRangeValidator, patternValidatorForName, numbersNotAllowedValidator];

    if (props.familyNameMandatory && props.familyNameMessage) {
        familyNameValidators.push(mandatoryFNCustomMessageValidator);
    }
    else if (props.familyNameMandatory) {
        familyNameValidators.push(mandatoryValidator);
    }

    if (props.givenNamesMandatory && props.givenNamesMessage) {
        givenNameValidators.push(mandatoryGNCustomMessageValidator);
    }
    else if (props.givenNamesMandatory) {
        givenNameValidators.push(mandatoryValidator);
    }
    givenNameValidators.push(allSpacesValidator);

    let dateOfEventValidators:any = [mandatoryValidator, dateInputValidator, futureDateValidator];
    if (props.registrationIsDate) { dateOfEventValidators.push(registrationDateCheckWhenDateOfEventChangesValidator); }

    let registrationNumberValidators: any = [allSpacesValidator];
    if (!props.registrationNumberAllowAlphanumeric) { registrationNumberValidators.push(numbersOnlyValidator); }
    registrationNumberValidators.push(mustHaveRegistrationValueIfCertificateNumberEmptyValidator, registrationValueLengthValidator, registrationNumberLengthValidator);

    let valids = [certificateNumberValid, familyNameValid1, familyNameValid2, givenNamesValid1, givenNamesValid2, dateOfEventValid, registrationValueValid];
    let mandatories = [props.state, familyName1, familyName2, dateOfEvent];
    if(props.givenNamesMandatory){        
        mandatories.push(givenNames1,givenNames2);
    }

    if (typeof certificateNumber === "string") { certificateNumber.trim(); }
    if (typeof registrationValue === "string") { registrationValue.trim(); }

    useEffect(() => {
        const registrationType = `Registration ${props.registrationIsDate? "Date" : "Number"}`;
        let fields = new Map<string, string>();
        fields.set("state",props.state);
        fields.set("familyName1",familyName1.trim().replace(/’/g, "'"));
        fields.set("givenNames1",givenNames1.trim().replace(/’/g, "'"));
        fields.set("familyName2",familyName2.trim().replace(/’/g, "'"));
        fields.set("givenNames2",givenNames2.trim().replace(/’/g, "'"));
        fields.set("dateOfEvent",dateOfEvent);
        fields.set("certificateNumber",certificateNumber);
        fields.set("registrationValue",registrationValue);
        fields.set("registrationType",registrationType);

        props.setFormFields(fields);
        props.setIsFormValid(valids.every(v => v) && mandatories.every(m => m.length > 0) && (certificateNumber.length >0 || registrationValue.length>0));
    }, [...valids, ...mandatories, certificateNumber, registrationValue]);

    function registrationValueSelected(checked: boolean) {
        setCertificateNumber('');
        setCertificateNumberValid(true);
        setRegValueSelected(checked);
        setCertNoSelected(!checked);
    }

    function certificateNumberSelected(checked: boolean) {
        setRegistrationValue('');
        setRegistrationValueValid(true);
        setCertNoSelected(checked);
        setRegValueSelected(!checked);
    }

    return (
        <>
            <form className="qg-forms-v2">
                <ol className="questions">
                    <li>
                        <fieldset id="fset-participant1">
                            <legend>Participant 1</legend>
                            <ul className="questions">
                                <li>
                                    <LabelInput 
                                        id="family-name1" 
                                        label="Family name" 
                                        inputValue={familyName1}
                                        isInputValid={familyNameValid1}
                                        setInputValue={setFamilyName1}
                                        setInputValid={setFamilyNameValid1}
                                        validators={familyNameValidators}
                                        mandatory={true}
                                    />
                                </li>
                                <li>
                                    <LabelInput 
                                        id="given-names1" 
                                        label="Given name/s" 
                                        persistentHint={!props.givenNamesMandatory && " Given name/s must be entered if they appear on your document. Separate given names with a space."}
                                        inputValue={givenNames1}
                                        isInputValid={givenNamesValid1}
                                        setInputValue={setGivenNames1}
                                        setInputValid={setGivenNamesValid1}
                                        validators={givenNameValidators}
                                        mandatory={props.givenNamesMandatory}
                                    />
                                </li>
                            </ul>
                        </fieldset>
                    </li>
                    <li>
                        <fieldset id="fset-participant2">
                            <legend>Participant 2</legend>
                            <ul className="questions">
                                <li>
                                    <LabelInput 
                                        id="family-name2" 
                                        label="Family name" 
                                        inputValue={familyName2}
                                        isInputValid={familyNameValid2}
                                        setInputValue={setFamilyName2}
                                        setInputValid={setFamilyNameValid2}
                                        validators={familyNameValidators}
                                        mandatory={true}
                                    />
                                </li>
                                <li>
                                    <LabelInput 
                                        id="given-names2" 
                                        label="Given name/s" 
                                        persistentHint={!props.givenNamesMandatory && " Given name/s must be entered if they appear on your document. Separate given names with a space."}
                                        inputValue={givenNames2}
                                        isInputValid={givenNamesValid2}
                                        setInputValue={setGivenNames2}
                                        setInputValid={setGivenNamesValid2}
                                        validators={givenNameValidators}
                                        mandatory={props.givenNamesMandatory}
                                    />
                                </li>
                            </ul>
                        </fieldset>
                    </li>
                    <li>
                        <fieldset id="fset-marriage-certificate"> 
                            <legend><h2 className="label">Certificate details</h2></legend>
                            <ul className="questions selection-group">
                                <li>
                                    <LabelDateInput
                                        id="date-of-event" 
                                        label="Date of event" 
                                        inputValue={dateOfEvent}
                                        isInputValid={dateOfEventValid}
                                        setInputValue={setDateOfEvent}
                                        setInputValid={setDateOfEventValid}
                                        validators={dateOfEventValidators}
                                    />
                                </li>
                                <li><p><abbr className="required" title="required">*</abbr>You must provide one of the following:</p></li>
                                
                                <div className="qg-forms-v2__radio">
                                    <li>
                                        <div className="choices">
                                            <input 
                                                type="radio" 
                                                name="certificate-details-options" 
                                                id="registration-value"
                                                checked={regValueSelected}
                                                onChange={(e) => registrationValueSelected(e.target.checked)}
                                            />
                                            <label htmlFor="registration-value">{props.registrationIsDate ? "Registration Date" : "Registration Number"}</label>
                                        </div>
                                        {regValueSelected && <div style={{paddingLeft: 48}}>                                       
                                            { props.registrationIsDate ?
                                                <LabelDateInput 
                                                    id="registration-value" 
                                                    label="Registration Date" 
                                                    inputValue={registrationValue}
                                                    inputHint={registrationValueHint}
                                                    isInputValid={registrationValueValid}
                                                    setInputValue={setRegistrationValue}
                                                    setInputValid={setRegistrationValueValid}
                                                    setInputHint={setRegistrationValueHint}
                                                    validators={[
                                                        mustHaveRegistrationValueIfCertificateNumberEmptyValidator, 
                                                        dateInputValidator,
                                                        registrationDateMustBeOnOrAfterDateOfEventValidator,
                                                        futureDateValidator
                                                    ]}
                                                    labelClassName="alt-required"
                                                    mandatory={false}
                                                    hideLabel={true}
                                                />
                                                :
                                                <LabelInput 
                                                    id="registration-value" 
                                                    label="Registration Number" 
                                                    inputValue={registrationValue}
                                                    isInputValid={registrationValueValid}
                                                    setInputValue={setRegistrationValue}
                                                    setInputValid={setRegistrationValueValid}
                                                    validators={registrationNumberValidators}
                                                    persistentHint={<><a href="https://www.mygovid.gov.au/verify-a-change-in-name-marriage-certificate" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Registration Number?</a></>}
                                                    hintPosition="below"
                                                    labelClassName="alt-required"
                                                    mandatory={false}
                                                    hideLabel={true}
                                                />
                                            }
                                        </div>}  
                                    </li>
                                    <li>
                                        <div className="choices">
                                            <input 
                                                type="radio" 
                                                name="certificate-details-options" 
                                                id="certificate-number"
                                                checked={certNoSelected}
                                                onChange={(e) => certificateNumberSelected(e.target.checked)}
                                            />
                                            <label htmlFor="certificate-number">Certificate Number</label>
                                        </div>
                                        {certNoSelected && <div style={{paddingLeft: 48}}>                                                                             
                                            <LabelInput 
                                                id="certificate-number" 
                                                label="Certificate Number" 
                                                inputValue={certificateNumber}
                                                isInputValid={certificateNumberValid}
                                                setInputValue={setCertificateNumber}
                                                setInputValid={setCertificateNumberValid}
                                                validators={[
                                                    allSpacesValidator,                                         
                                                    mustHaveCertificateNumberIfRegistrationDateEmptyValidator,
                                                    leadingZeroValidator,
                                                    numbersOnlyValidator, 
                                                    certMaximumLengthValidator,
                                                    certificateNumberLengthValidator,
                                                ]}
                                                persistentHint={<><a href="https://www.mygovid.gov.au/verify-a-change-in-name-marriage-certificate" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Certificate Number?</a></>}
                                                hintPosition="below"
                                                labelClassName="alt-required"
                                                mandatory={false}
                                                hideLabel={true}
                                            />
                                        </div>}
                                    </li>
                                </div>

                            </ul>
                        </fieldset>
                    </li>
                </ol>
            </form>
        </>
    )
}

MarriageCertificateForm.defaultProps = {
    familyNameMandatory : true,
    givenNamesMandatory : true,
    familyNameMaxLength : 50,
    givenNameMaxLength : 60,
    nameFieldsCharsAllowedPattern: /[^a-zA-Z0-9 '’\-]/,
    nameFieldsCharsAllowedTrailingHint: " ' -",
    certificateNumberMaxLength: 10,
    certificateNumberLengthFixed: false,
    registrationNumberMaxLength: 10,
    registrationNumberLengthFixed: false,
    registrationNumberAllowAlphanumeric: false,
    registrationIsDate: false,
    validateLeadingZeros: false,
}

export default MarriageCertificateForm;
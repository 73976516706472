import React, { useState, useEffect } from "react";
import LabelSelect from "../../swe/base/label-select";
import SelectInputTypeProps from "../../swe/base/select-type";

interface SelectFormTypeProps extends Omit<SelectInputTypeProps,'options'>{
    options: Map<string,any>,
    noSelectionText?: string,
    infoMessage:any,
    validators?:any,
    setOptionsDisabled?:Array<string>,
}

export const SelectForm = (props:SelectFormTypeProps) => {
    return (
        <>
            <form className="qg-forms-v2">
                <ul className="questions initial-question">
                    <li>
                        <LabelSelect id={props.id} label={props.label}
                            inputValue={props.inputValue}
                            isInputValid={props.isInputValid}
                            mandatory={true}
                            noSelectionText={props.noSelectionText}
                            options={Array.from(props.options.keys())}
                            setOptionsDisabled={props.setOptionsDisabled}
                            setInputValue={props.setInputValue}
                            setInputValid={props.setInputValid}
                            validators={props.validators}
                        />
                    </li>
                </ul>
            </form>

            {
                props.infoMessage
            }

            {
                props.options.get(props.inputValue)
            }
        </>
    )
}

export default SelectForm;
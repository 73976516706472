import React, {useRef} from 'react';

/**
 * 
 * @param id: id and name html attribute value
 * @param heading: button text
 * @param type: CSS passed in from concrete button
 * @param action: action to take when button is clicked
 * @param disabled: whether the button is disabled or actionable. optional
 */
type Props = {
    id:string,
    heading: string,
    type: "btn-primary" | "btn-secondary" | "btn-default" | "btn-outline-dark" | "btn-link",
    action: (params:any) => any,
    disabled?: boolean,
    testId?: string,
};

function BaseButton({id, heading, type, action, disabled=false, testId} : Props) {
    let attributes: any = {};
    if (testId) { attributes['data-testid'] = testId; }

    const bRef = useRef();

    function bClick(event:any) {
        const loader = bRef.current!.children.length > 0 && bRef.current!.children![0].classList.contains('spinner-border') && bRef.current!.children![0];
        const btnText = bRef.current!.children.length > 0 && bRef.current!.children![1].classList.contains('button-text') && bRef.current!.children![1];
        if (bRef.current) {
            loader.classList.remove('hidden');
            btnText.innerText = "Submitting";
            bRef.current.disabled = true;
        }
        const wait = async() => {
            await action(event);
            if (bRef.current) {
                bRef.current.disabled = disabled;
                loader.classList.add('hidden');
                btnText.innerText = heading;
            }
        }
        wait();
    }

    return (
        <li>
            <button ref={bRef} id={id} type="button" className={"qg-btn " + type} onClick={bClick} disabled={disabled}{ ...attributes }>
                <span className="spinner-border hidden"><span className="sr-only" role="status">{"Processing..."}</span></span>
                <span className='button-text'>{heading}</span>
            </button>
        </li>
    );
}

export default BaseButton;
import React, { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../../hooks/useGlobalState";
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../constants';
import {extractIpn} from "../../utils/helpers";
import CriticalAlert from "../../components/swe/critical-alert";
import PrimaryButton from "../../components/swe/primary-button";

export const NamesDontMatch = () => {
    const linkContactUs = Constants.CONTACT_US_URL;
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
    
          try {
            if (!isAuthenticated) {
                // Throw exception if user not authenticated
                throw new Error("User is not authenticated!");
            }
            await getAccessTokenSilently({ ignoreCache: true });
            const claims = await getIdTokenClaims();
    
            if (Constants.DEBUG) {
              console.log('%c[NamesDontMatch] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
            }

            //As a temp workaround for ipn_status not getting updated in token, we are passing ip1p_nc staus via
            //globalState from travel-document-form based o the API response.
            // const gsIpn = globalState?.sessionToken?.ipn; 
            // const claimsIpn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
            // const sessionToken = localStorage.getItem('session_token');
            // saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            // globalState.sessionToken.ipn = (gsIpn === "ip1p_nc") ? gsIpn : claimsIpn;
            // if (Constants.DEBUG) { console.log('user claims ipn ->', globalState.sessionToken.ipn); }

            // IDP-3093 This is to prevent loosing the users nc status in their session tokens
            // between loggings causing them to be place in the wrong uplift stage
            //const gsIpn = globalState?.sessionToken?.ipn; 
            const claimsIpn = extractIpn(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
            let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token')!);
            if(sessionTokenJSON.ipn !== claimsIpn && (sessionTokenJSON.ipn !== "ip1p_nc")){            
                sessionTokenJSON.ipn = claimsIpn;
                localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
            }
            saveGlobalState({ sessionToken: sessionTokenJSON });

            if (Constants.DEBUG) {
              console.log('%c[NamesDontMatch] sessionTokenJSON: %o', 'color: #9c6;', sessionTokenJSON);
            }
            // Check if user has completed this step
            if (!globalState.nameDidnotMatch) {
              if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step completed. Redirecting to uplift"); }
              navigate("/ip-uplift", { replace: true });
            }
          }
          catch (error) {
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
            navigate("/unauthorised", { replace: true });
          }
    
        })();
      }, []);
    
    useEffect(() => {
      focusRef?.current?.focus();
    }, []);

    function tryAgain() {
      globalState.nameDidnotMatch = false;
      navigate("/ip-uplift/name-change-options", { replace: true });      
    }

    return (
        <>
          {isAuthenticated && 
            <div>
              <div tabIndex={-1} ref={focusRef}></div>
              <h1>Queensland Digital Identity</h1>

              <CriticalAlert 
                heading="Names still do not match" 
                message={<>
                  <p>You have successfully verified your name change document.</p>
                  <p>However your name on these documents is still different from the one in our system.</p>
                  <p>You can try again with a different name change document or a different second credential.</p> 
                  <p>If you do not have any alternative matching documents you will need to <a className="tmr-link" href={linkContactUs}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                </>} />

              <div className="my-3">
                  <ol className="questions">
                      <li>
                          <ul className='actions'>
                              <PrimaryButton id="try-again" heading='Try again' action={tryAgain} />
                          </ul>
                      </li>
                  </ol>
              </div>
            </div>
		      }
        </>
    )
}

export default NamesDontMatch;
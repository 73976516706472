import React, { useRef, useEffect } from "react";
import Product from "../../components/qdi/product";
import { useNavigate, useLocation } from 'react-router-dom';
import SecondaryButton from "../../components/swe/secondary-button";
import useGlobalState from "../../hooks/useGlobalState";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../../constants";
import { extractIpn } from "../../utils/helpers";

export const InterstateDLSelection = () => {
    const focusRef = useRef(null);
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { state } = useLocation();
    const ipnLevel = state && state.ipnLevel;
  
    useEffect(() => {
      (async () => {
  
        try {
          if (!isAuthenticated) {
            // Throw exception if user not authenticated
            throw new Error("User is not authenticated!");
          }
          await getAccessTokenSilently({ ignoreCache: true });
          const claims = await getIdTokenClaims();
          const ipn = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
          const acr = user!["http://oauth.tmr.qld.gov.au"].acr;
  
          if (Constants.DEBUG) {
            console.log('user claims  ipn ->', ipn);
            console.log('user object acr ->', acr);
            console.log('%c[InterstateDLSelection] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
              isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
          }
          
          saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
          if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;
  
          // Check if user should be here
          let mfaAvailable = globalState.userCompletedMFA;
          if (typeof mfaAvailable === 'undefined'
            || globalState.sessionToken.ipn !== ipnLevel
                || (user && user.email_verified === false)
                    || globalState.dlaUser) {
                        
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
            navigate("/ip-uplift", { replace: true });
          }
        }
        catch (error) {
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        //   const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        //   window.location.href = rp;
        navigate("/unauthorised", { replace: true });
        }  
      })();
    }, []);

    useEffect(() => {        
        focusRef?.current?.focus();
    }, []);

    const displayNSWDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "NSW" }});
    }

    const displayVICDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "VIC" }});
    }

    const displayWADriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "WA"}});
    }

    const displaySADriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "SA"}});
    }

    const displayTASDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "TAS"}});
    }

    const displayACTDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "ACT"}});
    }

    const displayNTDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, stateCode: "NT"} });
    }

    function navigateBackTo() {
        navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: ipnLevel} });
    }

    return (
        <>
            {isAuthenticated && 
                <>
                    <h1>Verify your {ipnLevel === "ip1" ? "first" : "second"} credential</h1> 
                    <div tabIndex={-1} ref={focusRef}>

                    </div>
                    <div>
                        <p>
                            <strong>Please select the Australian Issued Driver Licence (excluding Queensland) that you have from the list below:</strong>
                        </p>

                        <section className="row cards-identity qg-cards">
                            <Product
                                heading="New South Wales Driver Licence"
                                media={require('../../assets/images/NSW.png')} 
                                altText="New South Wales Driver Licence" action={displayNSWDriversLicenceForm}
                            />
                            <Product
                                heading="Victorian Driver Licence"
                                media={require('../../assets/images/VIC.png')}
                                altText="Victorian Driver Licence" action={displayVICDriversLicenceForm}
                            />
                            <Product
                                heading="West Australian Driver Licence"
                                media={require('../../assets/images/WA.png')} 
                                altText="West Australian Driver Licence" action={displayWADriversLicenceForm}
                            />
                            <Product
                                heading="South Australian Driver Licence"
                                media={require('../../assets/images/SA.png')} 
                                altText="South Australian Driver Licence" action={displaySADriversLicenceForm}
                            />
                            <Product
                                heading="Tasmanian Driver Licence"
                                media={require('../../assets/images/TAS.png')}
                                altText="Tasmanian Driver Licence" action={displayTASDriversLicenceForm}
                            />
                            <Product
                                heading="Australian Capital Territory Driver Licence"
                                media={require('../../assets/images/ACT.png')} 
                                altText="Australian Capital Territory Driver Licence" action={displayACTDriversLicenceForm}
                            />
                            <Product
                                heading="Northern Territory Driver Licence"
                                media={require('../../assets/images/NT.png')}
                                altText="Northern Territory Driver Licence" action={displayNTDriversLicenceForm}
                            />
                        </section>

                        <ol className="questions">
                            <li className="footer">
                                <ul className="actions">
                                    <SecondaryButton id="backButton" heading="Back" action={navigateBackTo} />
                                </ul>
                            </li>
                        </ol>
                    </div>
                </>
            }
        </>
    );
};

export default InterstateDLSelection;
import React, { ReactNode, useEffect } from "react";

interface LabelRadioTypeProps {
    id: string,
    label: string,
    labelClassName?: string,
    mandatory?: boolean,
    disabled?: boolean,
    options: Map<string, string>,
    inputValue: string,
    setInputValue: (params: string) => any,
    persistentHint?: any,
    hintPosition?: "above" | "below",
}

/**
 * Label and radio button component.
 * 
 * @param props 
 * @returns 
 */
function LabelRadio(props : LabelRadioTypeProps) {

    let optionItems: object[] = [];
    
    props.options.forEach((value: string, key: string) => {
        optionItems.push(
        <li key={key}>
            <input type="radio" id={`${props.id}-${key}`} name={`${props.id}`} disabled={props.disabled} 
                value={`${value}`} checked={props.inputValue === key}
                onChange={() => {props.setInputValue(key); return false}}
            />
            <label htmlFor={`${props.id}-${key}`}>{value}</label>
        </li>);
    });

    return (
        <>
            <fieldset>
                <legend>
                    <span className={`label ${props.labelClassName ? props.labelClassName : ''}`}>{props.label}</span>
                </legend>
                {(props.persistentHint && props.hintPosition==='above')? <small className="hint">{ props.persistentHint }</small> : null }
                <ul className="choices qg-forms-v2__radio">
                    {optionItems.map(i => i)}
                </ul>
                {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
            </fieldset>
        </>
    );
}

LabelRadio.defaultProps = {
    hintPosition: "above",
}

export default LabelRadio;

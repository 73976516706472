import React from 'react';

/**
 * Callout component.
 * 
 * @param heading: bold short text to display next to icon. optional.
 * @param message: text to display.
 * @param showIcon: flag to show icon or not. optional. defaults to true.
 */
type Props = {
    heading?: string,
    message: string,
    showIcon?: boolean
};

function Callout({heading, message,showIcon=true} : Props) {
    return (
        <section className="qg-callout__box qg-callout__grey-theme">
            { heading ?
                <div className="qg-callout__header col-12">
                    {showIcon ? <span className="qg-callout__icon"><span className="fa fa-comments"></span></span> : null }
                    <h2 className="qg-callout__title">{heading}</h2>
                </div>
            : null }

            <div className="qg-callout__content col-12">
                <div className="qg-callout__bottom-alignment">
                <div className="qg-callout__description">
                    <p>{message}</p>
                </div>
                </div>
            </div>
            </section>
    );
}

export default Callout;
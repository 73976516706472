import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import * as Constants from "../constants"
import CriticalAlert from '../components/swe/critical-alert';
import {extractCl} from "../utils/helpers";

export const TooManyResendAttempts = () => {
    const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, logout } = useAuth0();
    const navigate = useNavigate();
    const alertRef = useRef(null);
    let clValue:string|null;

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }

                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                clValue = extractCl(claims && claims['http://oauth.tmr.qld.gov.au'].acr);

            }
            catch (error) {
                navigate("/unauthorised", { replace: true });
            }
        })();
    }, []);
  
    useEffect(() => {
      (async () => {
        if (Constants.DEBUG) { console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.'); }
        logout({ localOnly: true });
  
        localStorage.clear();
        sessionStorage.clear();
      })();
    }, [])

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div>
                        <h1>Enter your email verification code</h1>
                        <CriticalAlert 
                            heading="Too many resend attempts" 
                            message={<>
                                        <p>We were unable to successfully verify your email address.</p>
                                        <p>Please <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                                    </>} 
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default TooManyResendAttempts;
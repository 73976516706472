import React, { Component } from "react";
import CriticalAlert from "../swe/critical-alert";

interface State {
    error: any; // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

class ErrorBoundary extends Component<State> {

    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        const errorType = event.reason.error;
        // if (errorType === 'login_required') {
        //     console.log(event);
        // }
        this.setState({
            error: event.reason
        });
        
        //Clear local and session storage as this will be a hardstop.
        localStorage.clear();
        sessionStorage.clear();
    }

    public state: State = {
        error: null
    };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    public componentDidCatch(error: Error, errorInfo: any) {
        console.error("Uncaught error:", error, errorInfo);
    }

    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    public render() {
        if (this.state.error) {
            return (
                <>
                    <h1>Queensland Digital Identity</h1>

                    <CriticalAlert 
                        heading="An unexpected error has occurred." 
                        message={
                            <>
                                <p>In order to continue you will need to return to the agency, service or application that you were attempting to use.</p>
                                <p>We appologise for any inconvenience.</p>
                            </>
                        }
                    />
                </>
            )
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
import React, { useState } from "react";
import Label from "./base/Label";
import InputTypeProps from "./base/input-type";
import { executeValidators } from "../../utils/validation/validator";


interface IndividualReferenceNumberInputTypeProps extends InputTypeProps {
    labelClassName?: string,
    inputLengthClassName?: string,
}

export const IndividualReferenceNumberInput = (props : IndividualReferenceNumberInputTypeProps) => {
    const [hint,setHint] = useState('');
    let  errorStyle = {
        borderColor: "#d60000",
    }

    const validateLastDigit = (event:any) => {
        let enteredNumber = event.target.value;

        if(enteredNumber.length > 1 || enteredNumber > 9) {
            enteredNumber = props.inputValue;
        }

        executeValidators(enteredNumber, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid!);
        props.setInputValue(enteredNumber);
    }

    return (
        <>
            <Label 
                id={props.id} 
                label={props.label} 
                mandatory={props.mandatory} 
                persistentHint={props.persistentHint && props.hintPosition==='above' ? props.persistentHint : null} 
                className={props.labelClassName}
            />
            {!props.isInputValid? <em className="error-label" style={{ color: "#d60000"}}>{(props.inputHint) ? props.inputHint : hint}</em> : null }
            <div className="container">
                <div className="row">
                    <div className="col-2 qdi-date-input-wrapper">
                        <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                            name={props.id}
                            id={props.id}
                            type="number"
                            value={props.inputValue}
                            pattern="[0-9]*"
                            max={9} 
                            min={1}
                            onBlur={validateLastDigit}
                            onChange={validateLastDigit}
                            disabled={props.disabled}
                            style={props.isInputValid ? {} : errorStyle }
                        />
                    </div>
                </div>
            </div>
            {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
        </>
    );
}

export default IndividualReferenceNumberInput;
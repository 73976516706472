import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import CriticalAlert from "../components/swe/critical-alert";
import { DEBUG } from "../constants";

const RequirementsNotMet = () => {

  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      if (DEBUG) { console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.'); }
      logout({ localOnly: true });

      localStorage.clear();
      sessionStorage.clear();
    })();
  }, [])

  return (
    <>
      <div>
        <h1>Your Queensland Digital Identity did not meet the requirements to continue</h1>
        <div tabIndex={-1}>

          <CriticalAlert message={
            <div>
              <p>In order to continue you will need to return to the agency, service or application that you were attempting to use.</p>
              <p>We apologise for any inconvenience.</p>
            </div>
          } />

        </div>
      </div>
    </>
  )
};

export default RequirementsNotMet;
import React from 'react';
import CriticalAlert from "../../swe/critical-alert"

type Props = {
    primaryCredentialLabel?:string,
}

/**
 * A red information alert which informs that the submitted document data does not match with existing data.
 * 
 */
function DocumentVerificationFailedAlert({ primaryCredentialLabel="physical card"}: Props) {
    return (
        <CriticalAlert heading="Verification failed" message={`The information you provided did not match our records. Please re-enter your information, and check the details entered exactly match your ${primaryCredentialLabel}.`}/>
    )
}

export default DocumentVerificationFailedAlert;
import React from 'react';
import BaseAlert from "./base/base-alert";

/**
 * A grey information alert.
 * 
 * @param message: text to display.
 */
 type Props = {
    message: any,
    showIcon?: boolean
};

function InfoAlert({message, showIcon} : Props) {
    return (
        <BaseAlert message={message} type="alert-info" icon="fa-info-circle" showIcon={showIcon}/>
    );
}

export default InfoAlert;
import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import * as Constants from "../constants"

import CriticalAlert from '../components/swe/critical-alert';

export const TooManyAttempts = () => {
    const { isAuthenticated, logout } = useAuth0();
    const navigate = useNavigate();
    const alertRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }
            }
            catch (error) {
            navigate("/unauthorised", { replace: true });
    }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div tabIndex={-1} ref={alertRef}>
                        <h1>Queensland Digital Identity</h1>
                        <CriticalAlert 
                            heading="Too many attempts" 
                            message={<>
                                <p>We were unable to successfully verify your details.</p>
                                <p>In order to continue, you will need to return to the agency, service or application that you were attempting to use.</p>
                                <p>We apologise for any inconvenience.</p>
                            </>} 
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default TooManyAttempts;
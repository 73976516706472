import React from "react";

const Loading = (props) => {
  let _container = Object.assign({}, !props.maxheight ? { className: "card col" } : {});
  let _body = Object.assign({}, !props.maxheight ? { className: "card-body" } : {});

  return (
    <>
      {<div {..._container} id="content-loading">
        <div {..._body}>
          <div className="qg-spinner qg-absolute-center" role="status">
            <div className="spinner-border"></div>
            <div className="qg-spinner-label">Loading...</div>
          </div>
        </div>
      </div>
      }
    </>);
};

export default Loading;

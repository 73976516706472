import React from "react";
import MarriageCertificateForm from "./marriage-certificate";
import MarriageCertificateType from "./marriage-certificate-type";

export const NSWMarriageCertificateForm = (props : Omit<MarriageCertificateType,'state'|'registrationIsDate'>) => {
    return (
        <MarriageCertificateForm 
            state="NSW" 
            givenNamesMandatory={false} 
            certificateNumberMaxLength={11}
            registrationNumberMaxLength={7}
            registrationIsDate={false}
            {...props}
        />
    )
}

export default NSWMarriageCertificateForm; 
import React from 'react';

/**
 * Displays a bordered heading and image with optional navigation when clicked on.
 * 
 * @param heading text for the heading to display
 * @param media uri of the image to display
 * @param action passed in function to call when clicked on
 * @param imgStyles styles for the card image  
 * @returns 
 */
type props= {
    heading: string,
    media: string,
    action?: (params:any) => any,
    disabled?: boolean,
    selected?: boolean,
    altText?: string,
    imgStyles?: any
}

export function Product ({heading, media, action,disabled=false, selected=false, altText, imgStyles={}} : props) {
    let attributes:any = {
    }
    if (!disabled) {
        attributes.onClick = action
    }
    return (
        <article className={ `qg-card qg-card__light-theme qg-card__clickable col-12${ selected && ' active' || '' }${ disabled && ' disabled' || '' }` } aria-expanded={selected} aria-selected={selected} aria-disabled={disabled} style={{ opacity: disabled ? 0.5 : 1.0 }}>
            <div className="content card-content" {...attributes} data-testid="dlButton">
                <div className="details card-content-wrap">
                    <div className="card-id-type-img">
                        <img 
                            src={media} 
                            className="id-type-img" 
                            alt={altText || heading} 
                            style={imgStyles}
                        />
                    </div>
                    <div className="card-id-type-text"><h4 className="qg-card__title">
                        {heading}
                    </h4>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default Product;
import React from 'react';
import BaseButton from './base/base-button';

/**
 * A Secondary Button, dark outline white interior.
 * 
 * @param id: id and name html attribute value
 * @param heading: bold short text to display next to icon. optional.
 * @param action: action to take when button is clicked
 */
 type Props = {
    id:string,
    heading: string,
    action: (params:any) => any,
    testId?: string,
};

function SecondaryButton({id, heading, action, testId} : Props) {
    let attributes: any = {};
    if (testId) { attributes.testId = testId; }
    return (
        <BaseButton id={id} heading={heading} action={action} type="btn-outline-dark" {...attributes} />
    );
}

export default SecondaryButton;
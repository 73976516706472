import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom';
import * as Constants from "../constants";

const SessionManager = () => {

    const navigate = useNavigate();
    const handleOnIdle = () => {
        navigate("/session-timeout", { replace: true });
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * Number(Constants.TIMEOUT),
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return null;
}

export default SessionManager;
import InputTypeProps from "../components/swe/base/input-type";
import { executeValidators } from "./validation/validator";

export default class AttributeFactory {

    static build(props:InputTypeProps, setHint: (params:string)=>any) {

        const isFlexibleDatePicker = (props.mode=='date' && props.placeholder);

        const validateInput = (event:any) => {
            const label = props.validationLabel ? props.validationLabel : props.label;
            if (isFlexibleDatePicker && event.target.value?.includes('-')){
                const ddMMyyyyDate = new Date(event.target.value).toLocaleString().split(',')[0];
                executeValidators(ddMMyyyyDate, props.validators, setHint, props.setInputHint!, label, props.setInputValid);
            } else {
                executeValidators(event.target.value, props.validators, setHint, props.setInputHint!, label, props.setInputValid);
            }
            props.setInputValue(event.target.value);
        };

        let attributes:any = {
            name:props.id,
            id:props.id,
            type:(isFlexibleDatePicker) ? "text" : (props.mode)? props.mode: "text",
            className:"form-control" + (props.isInputValid ? "" : " error input-alert"),
            value:(isFlexibleDatePicker) ? 
                (props.inputValue.includes('-') ? new Date(props.inputValue).toLocaleString().split(',')[0]  : props.inputValue) 
                : props.inputValue,
            onBlur:validateInput,
            onChange:validateInput,
            placeholder:props.placeholder,
            title: props.label,
            pattern: props.mode === "number" ? "[0-9]*" : ""
        };
        attributes['data-testid'] = props.id;
        if (props.maxLength) {
            attributes.maxLength = props.maxLength;
        }
        if (props.minLength) {
            attributes.minLength = props.minLength;
        }
        if (props.disabled) {
            attributes.disabled = props.disabled;
            attributes['aria-disabled'] = props.disabled;
            attributes['aria-readonly'] = props.disabled;
        }
        if (props.mandatory) {
            attributes.required = props.mandatory;
            attributes['aria-required'] = props.mandatory;
        }

        return attributes;
    }
}